export enum InventoryType {
    FARMS,
    CAPSULES,
    MICROGREENS,
    MINIFARMS,
    WEEFARMS,
    CABINETS,
    MUSHROOMS,
}

export function fromDTO(value: string): InventoryType {
    switch (value) {
        case 'FARMS':
            return InventoryType.FARMS;
        case 'CAPSULES':
            return InventoryType.CAPSULES;
        case 'MICROGREENS':
            return InventoryType.MICROGREENS;
        case 'MINIFARMS':
            return InventoryType.MINIFARMS;
        case 'WEEFARMS':
            return InventoryType.WEEFARMS;
        case 'CABINETS':
            return InventoryType.CABINETS;
        case 'MUSHROOMS':
            return InventoryType.MUSHROOMS;
        default:
            return null;
    }
}

const strArray = ['homefarms', 'Pflanzenkapseln', 'Microgreens', 'minifarms', 'weefarms', 'Vitrinen', 'Pilze'];

export function getInventoryTypeStringArray() {
    return strArray;
}

export function getInventoryTypeString(value: number) {
    if (value >= 0 && value < strArray.length) {
        return strArray[value];
    }
    return 'Keine Zuordnung: ' + value;
}
