import { backendAxios } from '../../../../core/axios';
import { RevenueAnalysisDTO } from '../../../../core/dtos/revenue.analysis.dto';
import { IAnalysisRevenueDataSource } from './interfaces';

export class AnalysisRevenueDataSource implements IAnalysisRevenueDataSource {
    private static instance: AnalysisRevenueDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new AnalysisRevenueDataSource();
        }
        return this.instance;
    }

    async postAnalysisRevenue(dto: RevenueAnalysisDTO): Promise<RevenueAnalysisDTO> {
        try {
            const response = await backendAxios.post<RevenueAnalysisDTO>(`/accounting/revenue`, dto);
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
