import { CustomerSearchByFilterDataSource } from './data-sources/customer.search.by.filter.data.source';
import { CustomerFilterDTO } from '../../../core/dtos/customer.filter.dto';
import { ICustomerSearchByFilterDataSource } from './data-sources/interfaces';
import { CustomerFilterResponseDTO } from '../../../core/dtos/customer.filter.response.dto';

export class CustomerSearchUseCase {
    private static instance: CustomerSearchUseCase | null = null;

    private dataSourceByFilter: ICustomerSearchByFilterDataSource;

    private constructor() {
        this.dataSourceByFilter = CustomerSearchByFilterDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new CustomerSearchUseCase();
        }
        return this.instance;
    }

    async byFilter(filterDTO: CustomerFilterDTO): Promise<CustomerFilterResponseDTO> {
        return await this.dataSourceByFilter.searchByFilter(filterDTO);
    }
}
