import { RevenueAnalysisDTO } from '../dtos/revenue.analysis.dto';
import { DurationType, fromDTO } from '../dtos/duration.type';
import { RevenueEntity } from './revenue.entity';
import { format } from 'date-fns';

export class RevenueAnalysisEntity {
    readonly durationType: DurationType;
    readonly startDate: Date;
    readonly endDate: Date;
    readonly list?: RevenueEntity[][];

    constructor(entity: RevenueAnalysisEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: RevenueAnalysisDTO) {
        return new RevenueAnalysisEntity({
            durationType: fromDTO(dto.durationType.toString()),
            startDate: new Date(dto.startDate),
            endDate: new Date(dto.endDate),
            list: dto.list ? dto.list.map(el1 => el1.map(el2 => RevenueEntity.fromDTO(el2))) : [],
        });
    }

    static toDTO(entity: RevenueAnalysisEntity): RevenueAnalysisDTO {
        return {
            durationType: entity.durationType,
            startDate: format(entity.startDate, "yyyy-MM-dd'T'HH:mm:ss"),
            endDate: format(entity.endDate, "yyyy-MM-dd'T'HH:mm:ss"),
            list: entity.list ? entity.list.map(el1 => el1.map(el2 => RevenueEntity.toDTO(el2))) : [],
        };
    }
}
