import { ClvDTO } from '../dtos/clv.dto';

export class ClvEntity {
    readonly clv: number;
    readonly flv: number;

    constructor(entity: ClvEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: ClvDTO) {
        return new ClvEntity({
            clv: dto.clv,
            flv: dto.flv,
        });
    }

    static toDTO(entity: ClvEntity): ClvDTO {
        return {
            clv: entity.clv,
            flv: entity.flv,
        };
    }
}
