import { ICustomerDetailsDataSource } from './data-sources/interfaces';
import { CustomerDetailsDataSource } from './data-sources/customer.details.data.source';
import { CustomerDTO } from '../../../core/dtos/customer.dto';

export class CustomerDetailUseCase {
    private static instance: CustomerDetailUseCase | null = null;

    private dataSourceCustomerDetails: ICustomerDetailsDataSource;

    private constructor() {
        this.dataSourceCustomerDetails = CustomerDetailsDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new CustomerDetailUseCase();
        }
        return this.instance;
    }

    async getCustomerDetails(customerId: string): Promise<CustomerDTO> {
        const dto = await this.dataSourceCustomerDetails.loadCustomerDetails(customerId);
        return dto;
    }
}
