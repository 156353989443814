import { backendAxios } from '../../../../core/axios';
import { OkrObjectiveDTO } from '../../../../core/dtos/okr/okr.objective.dto';
import { IObjectiveDataSource } from './interfaces';

export class ObjectiveDataSource implements IObjectiveDataSource {
    private static instance: ObjectiveDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new ObjectiveDataSource();
        }
        return this.instance;
    }

    async postCreateObjective(dto: OkrObjectiveDTO): Promise<OkrObjectiveDTO> {
        try {
            const response = await backendAxios.post<OkrObjectiveDTO>(`/okr/objective`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });

            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async putUpdateObjective(dto: OkrObjectiveDTO): Promise<OkrObjectiveDTO> {
        try {
            const response = await backendAxios.put<OkrObjectiveDTO>(`/okr/objective/${dto.id}`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });

            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async deleteObjective(id: string): Promise<void> {
        try {
            const response = await backendAxios.delete<void>(`/okr/objective/${id}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
