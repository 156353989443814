import { OkrKeyResultEntity } from '../../../core/entities/okr.keyResult.entity';
import { IKeyResultDataSource } from './data-sources/interfaces';
import { KeyResultDataSource } from './data-sources/okr.keyResult.data.source';

export class OkrKeyResultUseCase {
    private static instance: OkrKeyResultUseCase | null = null;
    private dataSourceKeyResult: IKeyResultDataSource;

    private constructor() {
        this.dataSourceKeyResult = KeyResultDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new OkrKeyResultUseCase();
        }
        return this.instance;
    }

    async createKeyResult(entity: OkrKeyResultEntity): Promise<OkrKeyResultEntity> {
        let dto = OkrKeyResultEntity.toDTO(entity);
        dto = await this.dataSourceKeyResult.postCreateKeyResult(dto);
        return OkrKeyResultEntity.fromDTO(dto);
    }

    async updateKeyResult(entity: OkrKeyResultEntity): Promise<OkrKeyResultEntity> {
        let dto = OkrKeyResultEntity.toDTO(entity);
        dto = await this.dataSourceKeyResult.putUpdateKeyResult(dto);
        return OkrKeyResultEntity.fromDTO(dto);
    }

    async deleteKeyResult(id: string): Promise<{ id: string }> {
        await this.dataSourceKeyResult.deleteKeyResult(id);
        return { id };
    }
}
