import { InventoryAnalysisDTO } from '../../../core/dtos/inventory.analysis.dto';
import { InventoryAnalysisEntity } from '../../../core/entities/inventory.analysis.entity';
import { IAnalysisInventoryDataSource } from './data-sources/interfaces';
import { AnalysisInventoryDataSource } from './data-sources/load.inventory.data.source';

export class AnalysisInventoryUseCase {
    private static instance: AnalysisInventoryUseCase | null = null;

    private dataSource: IAnalysisInventoryDataSource;

    private constructor() {
        this.dataSource = AnalysisInventoryDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new AnalysisInventoryUseCase();
        }
        return this.instance;
    }

    async getAnalysisInventory(entity: InventoryAnalysisEntity): Promise<InventoryAnalysisEntity> {
        let dto: InventoryAnalysisDTO = InventoryAnalysisEntity.toDTO(entity);
        dto = await this.dataSource.postAnalysisInventory(dto);
        return InventoryAnalysisEntity.fromDTO(dto);
    }
}
