import { JSONTree } from 'react-json-tree';
import { ZendeskCustomer } from '../../../core/dtos/customer.dto';
import './ZendeskCustomerComponent.scss';

interface ZendeskCustomerComponentProps {
    customer: ZendeskCustomer;
}

export function ZendeskCustomerComponent(props: ZendeskCustomerComponentProps) {
    return <>{props.customer && <JSONTree data={props.customer} />}</>;
}
