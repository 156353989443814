import { Button, Form, Modal } from 'react-bootstrap';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import React from 'react';
import { UdpUserDTO } from '../../../core/dtos/udp.user.dto';
import { useAppSelector } from '../../../state/hooks';
import { selectUdpUserList } from '../../../state/screens/user/userSlice';
import { OkrKeyResultEntity } from '../../../core/entities/okr.keyResult.entity';

type Props = {
    onSubmit: ({
        id,
        name,
        weight,
        isInitiative,
        countCurrent,
        countMax,
        progress,
        userList,
    }: {
        id: string;
        name: string;
        weight: number;
        isInitiative: boolean;
        countCurrent: number;
        countMax: number;
        progress: number;
        userList: UdpUserDTO[];
    }) => any;
};

const ModalKeyResult = React.forwardRef((props: Props, ref) => {
    const [keyResult, setKeyResult] = useState<OkrKeyResultEntity>();
    const [name, setName] = useState<string>('');
    const [weight, setWeight] = useState<number>(1);
    const [countCurrent, setCountCurrent] = useState<string>('');
    const [countMax, setCountMax] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);
    const [showModal, setShowModal] = useState(false);
    const [isInitiative, setIsInitiative] = useState(false);

    const userList: UdpUserDTO[] = useAppSelector(selectUdpUserList);
    const [selectedUserList, setSelectedUserList] = useState<UdpUserDTO[]>([]);

    useImperativeHandle(ref, () => ({
        openModal(entity: OkrKeyResultEntity) {
            setKeyResult(entity);
            setName(entity?.name ?? '');
            setWeight(entity?.weight ?? 1);
            setCountCurrent(entity?.countCurrent ? entity.countCurrent + '' : '');
            setCountMax(entity?.countMax ? entity.countMax + '' : '');
            setProgress(entity?.progress ?? 0);
            setIsInitiative(entity?.isInitiative ?? false);
            setSelectedUserList(entity?.userList ?? []);
            setShowModal(true);
        },
        closeModal() {
            setShowModal(false);
        },
    }));

    useEffect(() => {
        if (countMax.length > 0) {
            let valueMax = Number(countMax);
            if (Number.isNaN(valueMax)) {
                setCountMax('');
            } else {
                let valueCurrent = 0;
                valueCurrent = Number(countCurrent);
                if (Number.isNaN(valueCurrent)) {
                    setCountCurrent('');
                } else {
                    var intvalue = Math.trunc((valueCurrent / valueMax) * 100);
                    setProgress(intvalue);
                }
            }
        }
    }, [countCurrent, countMax]);

    const onKeyDown = event => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    };

    const onSubmit = () => {
        const list = selectedUserList.filter((el, idx) => selectedUserList.indexOf(el) === idx);
        props.onSubmit({
            id: keyResult?.id,
            name,
            weight,
            isInitiative,
            countCurrent: Number(countCurrent),
            countMax: Number(countMax),
            progress,
            userList: list,
        });
    };

    const checkUser = useCallback(
        (user: UdpUserDTO, checked: boolean) => {
            let list = selectedUserList.filter(el => true);
            const idx = selectedUserList.findIndex(el => el.uid === user.uid);
            if (idx < 0 && checked) {
                list.push(user);
            } else if (idx >= 0) {
                list.splice(idx, 1);
            }
            setSelectedUserList(list);
        },
        [selectedUserList],
    );

    const isInList = useCallback(
        (user: UdpUserDTO) => {
            let value = false;
            selectedUserList.forEach(u => {
                if (u.uid === user.uid) {
                    value = true;
                }
            });
            return value;
        },
        [selectedUserList],
    );

    return (
        <div className="modal show" style={{ display: 'block', position: 'initial' }}>
            <Modal show={showModal}>
                <Modal.Header closeButton>
                    {keyResult ? (
                        <Modal.Title>KeyResult bearbeiten</Modal.Title>
                    ) : (
                        <Modal.Title>KeyResult erstellen</Modal.Title>
                    )}
                </Modal.Header>

                <Modal.Body>
                    <Form style={{ marginTop: '10px' }} onKeyDown={onKeyDown}>
                        <Form.Label htmlFor="name">Name</Form.Label>
                        <Form.Control
                            type="text"
                            id="name"
                            value={name}
                            onChange={e => {
                                setName(e.target.value);
                            }}
                        />
                        <Form>
                            <Form.Label htmlFor="weight" style={{ marginTop: '20px' }}>
                                Gewichtung
                            </Form.Label>
                            <Form.Control
                                id="weight"
                                value={weight}
                                onChange={e => {
                                    setWeight(Number(e.target.value));
                                }}
                            />
                        </Form>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form style={{ flex: 0.5, marginRight: '20px' }}>
                                <Form.Label htmlFor="name" style={{ marginTop: '20px' }}>
                                    Count Current
                                </Form.Label>
                                <Form.Control
                                    disabled={!countMax || countMax.length === 0}
                                    id="countCurrent"
                                    value={countCurrent}
                                    onChange={e => {
                                        setCountCurrent(e.target.value);
                                    }}
                                />
                            </Form>
                            <Form style={{ flex: 0.5 }}>
                                <Form.Label htmlFor="name" style={{ marginTop: '20px' }}>
                                    Count Max
                                </Form.Label>
                                <Form.Control
                                    id="countMax"
                                    value={countMax}
                                    onChange={e => {
                                        setCountMax(e.target.value);
                                    }}
                                />
                            </Form>
                        </div>
                        <Form>
                            <Form.Label htmlFor="progress" style={{ marginTop: '20px' }}>
                                Fortschritt
                            </Form.Label>
                            <Form.Control
                                disabled={countMax?.length > 0}
                                type="number"
                                min="0"
                                max="100"
                                id="progress"
                                value={progress}
                                onChange={e => {
                                    setProgress(Number(e.target.value));
                                }}
                            />
                        </Form>
                        <Form.Check
                            style={{ marginTop: '20px' }}
                            key={`isInitiative`}
                            inline
                            label={'Initiative'}
                            name={`isInitiative`}
                            type={'checkbox'}
                            onChange={e => setIsInitiative(e.target.checked)}
                            id={`isInitiative`}
                        />
                    </Form>

                    <h3 style={{ marginTop: '20px' }}>Personen</h3>
                    <Form>
                        {userList.map((user, idx) => (
                            <Form.Check
                                key={`user-${user.uid}`}
                                checked={isInList(user)}
                                label={
                                    user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email
                                }
                                name={`user-${user.uid}`}
                                type={'checkbox'}
                                onChange={e => checkUser(user, e.target.checked)}
                                id={`inline-checkbox-${idx}`}
                            />
                        ))}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Abbrechen
                    </Button>
                    <Button type="submit" variant="primary" onClick={onSubmit}>
                        {keyResult ? 'Speichern' : 'Hinzufügen'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
});

export default ModalKeyResult;
