import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerDTO } from '../../../core/dtos/customer.dto';
import { SyncedDTO } from '../../../core/dtos/synced.dto';
import { RootState } from '../../store';
import { customerLoadDetails, customerSearchByFilter } from './customerAsyncThunk';

export interface CustomerState {
    customerList: CustomerDTO[];
    synced: SyncedDTO;
    selectedCustomer: CustomerDTO;
    errorCode?: string;
    isLoading: boolean;
}

const initialState: CustomerState = {
    customerList: [],
    synced: undefined,
    selectedCustomer: undefined,
    errorCode: undefined,
    isLoading: false,
};

export const customerSlice = createSlice({
    name: 'customerSlice',
    initialState,
    reducers: {
        setCustomerList: (state, action: PayloadAction<CustomerDTO[]>) => {
            state.customerList = action.payload;
        },
        removeCustomerList: state => {
            state.customerList = undefined;
        },
        resetCustomerError: state => {
            state.errorCode = undefined;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(customerSearchByFilter.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(customerSearchByFilter.fulfilled, (state, action) => {
                state.isLoading = false;
                state.customerList = action.payload.list;
                state.synced = action.payload.synced;
            })
            .addCase(customerSearchByFilter.rejected, (state, action) => {
                console.error('customerSearchByFilter', action.error);
                state.customerList = [];
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(customerLoadDetails.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(customerLoadDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload?.id) {
                    state.selectedCustomer = action.payload;
                }
            })
            .addCase(customerLoadDetails.rejected, (state, action) => {
                console.error('customerLoadDetails', action.error);
                state.customerList = [];
                state.errorCode = action.error.code;
                state.isLoading = false;
            });
    },
});

export const selectCustomerList = (state: RootState) => state.customerSlice.customerList;
export const selectSynced = (state: RootState) => state.customerSlice.synced;
export const selectSelectedCustomer = (state: RootState) => state.customerSlice.selectedCustomer;
export const selectCustomerListIsLoading = (state: RootState) => state.customerSlice.isLoading;
export const selectCustomerListErrorCode = (state: RootState) => state.customerSlice.errorCode;

export default customerSlice.reducer;
