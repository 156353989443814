import { JSONTree } from 'react-json-tree';
import { BillbeeCustomer } from '../../../core/dtos/customer.dto';
import './BillbeeCustomerComponent.scss';

interface BillbeeCustomerComponentProps {
    customer: BillbeeCustomer;
}

export function BillbeeCustomerComponent(props: BillbeeCustomerComponentProps) {
    return <>{props.customer && <JSONTree data={props.customer} />}</>;
}
