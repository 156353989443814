import { backendAxios } from '../../../../core/axios';
import { OkrCategoryDTO } from '../../../../core/dtos/okr/okr.category.dto';
import { OkrCategoryTypeDTO } from '../../../../core/dtos/okr/okr.categoryType.dto';
import { IOkrCategoryDataSource } from './interfaces';

export class OkrCategoryDataSource implements IOkrCategoryDataSource {
    private static instance: OkrCategoryDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new OkrCategoryDataSource();
        }
        return this.instance;
    }

    async postCreateCategory(dto: OkrCategoryTypeDTO): Promise<OkrCategoryDTO> {
        try {
            const response = await backendAxios.post<OkrCategoryDTO>(`/okr/category`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async putUpdateCategory(dto: OkrCategoryDTO): Promise<OkrCategoryDTO> {
        try {
            const response = await backendAxios.put<OkrCategoryDTO>(`/okr/category`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
