import { OkrPeriodDTO } from '../dtos/okr/okr.period.dto';
import { format } from 'date-fns';
import { OkrCategoryEntity } from './okr.category.entity';

export class OkrPeriodEntity {
    readonly id?: string;
    readonly name: string;
    readonly startDate: Date;
    readonly endDate: Date;
    readonly categoryList: OkrCategoryEntity[];
    progress?: number;

    constructor(entity: OkrPeriodEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: OkrPeriodDTO) {
        return new OkrPeriodEntity({
            id: dto.id,
            name: dto.name,
            startDate: new Date(dto.startDate),
            endDate: new Date(dto.endDate),
            categoryList: dto.categoryList ? dto.categoryList.map(el => OkrCategoryEntity.fromDTO(el)) : [],
        });
    }

    static toDTO(entity: OkrPeriodEntity): OkrPeriodDTO {
        return {
            id: entity.id,
            name: entity.name,
            startDate: format(entity.startDate, "yyyy-MM-dd'T'HH:mm:ss"),
            endDate: format(entity.endDate, "yyyy-MM-dd'T'HH:mm:ss"),
            categoryList: entity.categoryList ? entity.categoryList.map(el => OkrCategoryEntity.toDTO(el)) : [],
        };
    }
}
