import { OkrKeyResultDTO } from '../dtos/okr/okr.keyResult.dto';
import { UdpUserDTO } from '../dtos/udp.user.dto';

export class OkrKeyResultEntity {
    readonly id?: string;
    readonly objectiveId: string;
    readonly name: string;
    readonly weight: number;
    readonly countCurrent?: number;
    readonly countMax?: number;
    readonly progress?: number;
    readonly isInitiative: boolean;
    readonly notes?: string;
    readonly userList: UdpUserDTO[];

    constructor(entity: OkrKeyResultEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: OkrKeyResultDTO) {
        return new OkrKeyResultEntity({
            id: dto.id,
            objectiveId: dto.objectiveId,
            name: dto.name,
            weight: dto.weight !== null && dto.weight !== undefined ? dto.weight : 1,
            countCurrent: dto.countCurrent,
            countMax: dto.countMax,
            progress: dto.progress,
            isInitiative: dto.isInitiative,
            notes: dto.notes,
            userList: dto.userList,
        });
    }

    static toDTO(entity: OkrKeyResultEntity): OkrKeyResultDTO {
        return {
            id: entity.id,
            objectiveId: entity.objectiveId,
            name: entity.name,
            weight: entity.weight,
            countCurrent: entity.countCurrent,
            countMax: entity.countMax,
            progress: entity.progress,
            isInitiative: entity.isInitiative,
            notes: entity.notes,
            userList: entity.userList,
        };
    }
}
