import { Button, Form, Modal } from 'react-bootstrap';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { selectUdpUserList } from '../../../state/screens/user/userSlice';
import { UdpUserDTO } from '../../../core/dtos/udp.user.dto';
import { selectCategoryListRecentPeriodOKR } from '../../../state/screens/okr/okrSlice';
import { OkrCategoryEntity } from '../../../core/entities/okr.category.entity';
import { OkrObjectiveEntity } from '../../../core/entities/okr.objective.entity';

type Props = {
    onSubmit: ({
        name,
        weight,
        leadUser,
        userList,
        categoryId,
    }: {
        name: string;
        weight: number;
        leadUser: UdpUserDTO | undefined;
        userList: UdpUserDTO[];
        categoryId: string;
    }) => any;
};

const ModalObjective = React.forwardRef((props: Props, ref) => {
    const userList: UdpUserDTO[] = useAppSelector(selectUdpUserList);
    const recentCategoryList: OkrCategoryEntity[] = useAppSelector(selectCategoryListRecentPeriodOKR);

    const [objective, setObjective] = useState<OkrObjectiveEntity>();
    const [name, setName] = useState<string>('');
    const [weight, setWeight] = useState<number>(1);
    const [showModal, setShowModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState<UdpUserDTO>();
    const [selectedUserList, setSelectedUserList] = useState<UdpUserDTO[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<OkrCategoryEntity>();

    useImperativeHandle(ref, () => ({
        openModal(entity: OkrObjectiveEntity) {
            setObjective(entity);
            setName(entity?.name ?? '');
            setWeight(entity?.weight ?? 1);
            setSelectedLead(entity?.leadUser);
            setSelectedUserList(entity?.userList ?? []);
            setShowModal(true);
        },
        closeModal() {
            setShowModal(false);
        },
    }));

    useEffect(() => {
        if (objective && recentCategoryList?.length > 0) {
            let cat = recentCategoryList.find(el => el.id === objective.categoryId);
            setSelectedCategory(cat);
        }
    }, [objective, recentCategoryList]);

    const onSubmit = () => {
        if (selectedLead) {
            selectedUserList.unshift(selectedLead);
        }
        const list = selectedUserList.filter((el, idx) => selectedUserList.indexOf(el) === idx);
        props.onSubmit({
            name,
            weight: weight,
            leadUser: selectedLead,
            userList: list,
            categoryId: selectedCategory.id,
        });
    };

    const checkUser = useCallback(
        (user: UdpUserDTO, checked: boolean) => {
            let list = selectedUserList.filter(el => true);
            const idx = selectedUserList.findIndex(el => el.uid === user.uid);
            if (idx < 0 && checked) {
                list.push(user);
            } else if (idx >= 0) {
                list.splice(idx, 1);
            }
            setSelectedUserList(list);
        },
        [selectedUserList],
    );

    const isInList = useCallback(
        (user: UdpUserDTO) => {
            let value = false;
            selectedUserList.forEach(u => {
                if (u.uid === user.uid) {
                    value = true;
                }
            });
            return value;
        },
        [selectedUserList],
    );

    return (
        <div className="modal show" style={{ display: 'block', position: 'initial' }}>
            <Modal
                show={showModal}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onSubmit();
                    }
                }}>
                <Modal.Header closeButton>
                    {objective ? (
                        <Modal.Title>Objective bearbeiten</Modal.Title>
                    ) : (
                        <Modal.Title>Objective erstellen</Modal.Title>
                    )}
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Label style={{ marginTop: 10 }}>Name</Form.Label>
                        <Form.Control
                            type="text"
                            id="name"
                            value={name}
                            onChange={e => {
                                setName(e.target.value);
                            }}
                        />
                        <Form.Label style={{ marginTop: 10 }}>Kategorie</Form.Label>
                        <Form.Select
                            value={selectedCategory?.id}
                            onChange={e => {
                                let cat = recentCategoryList.find(el => el.id === e.target.value);
                                setSelectedCategory(cat);
                            }}>
                            <option value={undefined} key={`recent-category-empty`}>
                                {'-'}
                            </option>
                            {recentCategoryList.map((cat, idx) => (
                                <option value={cat.id} key={`recent-category-${cat.id}`}>
                                    {cat.categoryType.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form>
                    <Form>
                        <Form.Label htmlFor="weight" style={{ marginTop: '20px' }}>
                            Gewichtung
                        </Form.Label>
                        <Form.Control
                            id="weight"
                            value={weight}
                            onChange={e => {
                                setWeight(Number(e.target.value));
                            }}
                        />
                    </Form>

                    <h2 style={{ marginTop: '20px' }}>Personen</h2>
                    <Form.Label>Lead</Form.Label>
                    <Form.Select
                        value={selectedLead?.uid}
                        onChange={e => {
                            const user = userList.find(el => el.uid === e.target.value);
                            setSelectedLead(user);
                        }}>
                        <option value={undefined} key={`lead-user-empty`}>
                            {'-'}
                        </option>
                        {userList.map((user, idx) => (
                            <option value={user.uid} key={`lead-user-${user.uid}`}>
                                {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Label style={{ marginTop: '20px' }}>Beteiligte</Form.Label>
                    <Form>
                        {userList.map((user, idx) => (
                            <Form.Check
                                key={`user-${user.uid}`}
                                checked={isInList(user)}
                                label={
                                    user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email
                                }
                                name={`user-${user.uid}`}
                                type={'checkbox'}
                                onChange={e => checkUser(user, e.target.checked)}
                                id={`inline-checkbox-${idx}`}
                            />
                        ))}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Abbrechen
                    </Button>
                    <Button type="submit" variant="primary" onClick={onSubmit}>
                        {objective ? 'Speichern' : 'Hinzufügen'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
});

export default ModalObjective;
