import { IUdpUserDataSource } from './interfaces';
import { UdpUserDTO } from '../../../../core/dtos/udp.user.dto';
import { backendAxios } from '../../../../core/axios';

export class UdpUserDataSource implements IUdpUserDataSource {
    private static instance: UdpUserDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new UdpUserDataSource();
        }
        return this.instance;
    }

    async loginUser(dto: UdpUserDTO): Promise<UdpUserDTO> {
        try {
            const response = await backendAxios.get<UdpUserDTO>(`/udp/users/${dto.uid}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async updateUser(dto: UdpUserDTO): Promise<UdpUserDTO> {
        try {
            const response = await backendAxios.put<UdpUserDTO>(`/udp/users/${dto.uid}`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async getAllUsers(): Promise<UdpUserDTO[]> {
        try {
            const response = await backendAxios.get<UdpUserDTO[]>(`/udp/users`, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
