import { IOkrPeriodDataSource } from './data-sources/interfaces';
import { OkrPeriodDataSource } from './data-sources/okr.period.data.source';
import { OkrPeriodEntity } from '../../../core/entities/okr.period.entity';
import { OkrCreatePeriodDTO } from '../../../core/dtos/okr/okr.period.dto';

export class OkrPeriodUseCase {
    private static instance: OkrPeriodUseCase | null = null;
    private dataSourceOkrPeriod: IOkrPeriodDataSource;

    private constructor() {
        this.dataSourceOkrPeriod = OkrPeriodDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new OkrPeriodUseCase();
        }
        return this.instance;
    }

    async getPeriod(periodId: string): Promise<OkrPeriodEntity> {
        const dto = await this.dataSourceOkrPeriod.getPeriod(periodId);
        return OkrPeriodEntity.fromDTO(dto);
    }

    async getAllPeriods(): Promise<OkrPeriodEntity[]> {
        const dtoList = await this.dataSourceOkrPeriod.getAllPeriods();
        return dtoList.map(el => OkrPeriodEntity.fromDTO(el));
    }

    async createPeriod(dto: OkrCreatePeriodDTO): Promise<OkrPeriodEntity> {
        const response = await this.dataSourceOkrPeriod.postCreatePeriod(dto);
        return OkrPeriodEntity.fromDTO(response);
    }

    async updatePeriod(entity: OkrPeriodEntity): Promise<OkrPeriodEntity> {
        let dto = OkrPeriodEntity.toDTO(entity);
        dto = await this.dataSourceOkrPeriod.putUpdatePeriod(dto);
        return OkrPeriodEntity.fromDTO(dto);
    }
}
