import { useAppDispatch } from '../../state/hooks';
import { KeyResultComponent } from './KeyResultComponent';
import { Table } from 'react-bootstrap';
import ModalKeyResult from './modals/ModalKeyResult';
import { useRef } from 'react';
import {
    createKeyResultOKR,
    deleteKeyResultOKR,
    deleteObjectiveOKR,
    updateKeyResultOKR,
    updateObjectiveOKR,
} from '../../state/screens/okr/okrAsyncThunk';
import { formatToPercent, getOkrProgressColor } from '../../core/utils';
import { IconButtonMenu } from '../IconButtonMenu';
import ModalObjective from './modals/ModalObjective';
import './ObjectiveComponent.scss';
import { OkrObjectiveEntity } from '../../core/entities/okr.objective.entity';

interface Props {
    objective: OkrObjectiveEntity;
}

export function ObjectiveComponent(props: Props) {
    const dispatch = useAppDispatch();
    const modalRefObjective = useRef<any>();
    const modalRefKeyResult = useRef<any>();

    return (
        <div
            style={{
                flex: 1,
                marginTop: '10px',
                marginBottom: '10px',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: props.objective.leadUser?.okrColor ?? 'transparent',
            }}>
            <Table striped hover>
                <thead style={{ backgroundColor: 'transparent' }}>
                    <tr
                        style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: '3px',
                        }}>
                        <th style={{ borderTopLeftRadius: '10px' }}>
                            {'Objective'}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {props.objective.userList
                                    .filter(el => el?.okrColor !== props.objective.leadUser?.okrColor)
                                    .map((item, index) => (
                                        <div
                                            key={`objective-color-${item.uid}`}
                                            style={{
                                                width: 15,
                                                height: 15,
                                                borderRadius: 7,
                                                margin: 3,
                                                backgroundColor: item?.okrColor,
                                                border: '1px solid black',
                                            }}></div>
                                    ))}
                            </div>
                        </th>
                        <th>{props.objective.name}</th>
                        <th>
                            <span
                                style={{ whiteSpace: 'nowrap', color: getOkrProgressColor(props.objective.progress) }}>
                                {formatToPercent(props.objective.progress)}
                            </span>
                        </th>
                        <th style={{ borderTopRightRadius: '10px' }}>
                            <IconButtonMenu
                                onEdit={() => modalRefObjective.current.openModal(props.objective)}
                                onDelete={() => dispatch(deleteObjectiveOKR(props.objective.id))}
                                onAdd={() => {
                                    modalRefKeyResult.current.openModal();
                                }}
                                addText="KeyResult"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.objective.keyResultList
                        .filter(el => !el.isInitiative)
                        .map((item, index) => (
                            <KeyResultComponent
                                keyResult={item}
                                leadUser={props.objective.leadUser}
                                key={`okr-key-result-${item.id}`}
                                onEdit={() => {
                                    modalRefKeyResult.current.openModal(item);
                                }}
                                onDelete={() => {
                                    modalRefKeyResult.current.keyResult = null;
                                    dispatch(deleteKeyResultOKR(item.id));
                                }}
                            />
                        ))}
                    {props.objective.keyResultList
                        .filter(el => el.isInitiative)
                        .map((item, index) => (
                            <KeyResultComponent
                                keyResult={item}
                                leadUser={props.objective.leadUser}
                                key={`okr-initiative-${item.id}`}
                                onEdit={() => {
                                    modalRefKeyResult.current.openModal(item);
                                }}
                                onDelete={() => {
                                    modalRefKeyResult.current.keyResult = null;
                                    dispatch(deleteKeyResultOKR(item.id));
                                }}
                            />
                        ))}
                </tbody>
            </Table>
            <ModalObjective
                ref={modalRefObjective}
                onSubmit={data => {
                    dispatch(
                        updateObjectiveOKR({
                            ...props.objective,
                            name: data.name,
                            weight: data.weight,
                            leadUser: data.leadUser,
                            userList: data.userList,
                            categoryId: data.categoryId,
                        }),
                    );
                    modalRefObjective.current.closeModal();
                }}
            />
            <ModalKeyResult
                ref={modalRefKeyResult}
                onSubmit={data => {
                    if (data.id) {
                        dispatch(
                            updateKeyResultOKR({
                                id: data.id,
                                objectiveId: props.objective.id,
                                name: data.name,
                                weight: data.weight,
                                isInitiative: data.isInitiative,
                                countCurrent: data.countCurrent,
                                countMax: data.countMax,
                                progress: data.progress,
                                userList: data.userList,
                            }),
                        );
                    } else {
                        dispatch(
                            createKeyResultOKR({
                                name: data.name,
                                weight: data.weight,
                                objectiveId: props.objective.id,
                                isInitiative: data.isInitiative,
                                countCurrent: data.countCurrent,
                                countMax: data.countMax,
                                progress: data.progress,
                                userList: data.userList,
                            }),
                        );
                    }
                    modalRefKeyResult.current.closeModal();
                }}
            />
        </div>
    );
}
