import { devToolsEnhancer } from '@redux-devtools/extension';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import okrSliceReducer from './screens/okr/okrSlice';
import accountingSliceReducer from './screens/accounting/accountingSlice';
import customerSliceReducer from './screens/customer/customerSlice';
import userSliceReducer from './screens/user/userSlice';

const enhancer: any = { realtime: true };
export const store = configureStore({
    reducer: {
        okrSlice: okrSliceReducer,
        accountingSlice: accountingSliceReducer,
        customerSlice: customerSliceReducer,
        udpUser: userSliceReducer,
    },
    devTools: false,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
    enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(devToolsEnhancer(enhancer)),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
