import { backendAxios } from '../../../../core/axios';
import { CustomerDTO } from '../../../../core/dtos/customer.dto';
import { ICustomerDetailsDataSource } from './interfaces';

export class CustomerDetailsDataSource implements ICustomerDetailsDataSource {
    private static instance: CustomerDetailsDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new CustomerDetailsDataSource();
        }
        return this.instance;
    }

    async loadCustomerDetails(customerId: string): Promise<CustomerDTO> {
        try {
            const response = await backendAxios.get<CustomerDTO>(`/customers/${customerId}`, {
                headers: {
                    Accept: 'application/json',
                },
            });

            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
