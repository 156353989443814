import { OkrObjectiveDTO } from '../dtos/okr/okr.objective.dto';
import { UdpUserDTO } from '../dtos/udp.user.dto';
import { OkrKeyResultEntity } from './okr.keyResult.entity';

export class OkrObjectiveEntity {
    readonly id?: string;
    readonly categoryId: string;
    readonly name: string;
    readonly weight: number;
    readonly keyResultList?: OkrKeyResultEntity[];
    readonly leadUser?: UdpUserDTO;
    readonly userList: UdpUserDTO[];
    progress?: number;

    constructor(entity: OkrObjectiveEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: OkrObjectiveDTO) {
        return new OkrObjectiveEntity({
            id: dto.id,
            categoryId: dto.categoryId,
            name: dto.name,
            weight: dto.weight !== null && dto.weight !== undefined ? dto.weight : 1,
            progress: dto.progress,
            keyResultList: dto.keyResultList ? dto.keyResultList.map(el => OkrKeyResultEntity.fromDTO(el)) : [],
            leadUser: dto.leadUser,
            userList: dto.userList,
        });
    }

    static toDTO(entity: OkrObjectiveEntity): OkrObjectiveDTO {
        return {
            id: entity.id,
            categoryId: entity.categoryId,
            name: entity.name,
            weight: entity.weight,
            progress: entity.progress,
            keyResultList: entity.keyResultList ? entity.keyResultList.map(el => OkrKeyResultEntity.toDTO(el)) : [],
            leadUser: entity.leadUser,
            userList: entity.userList,
        };
    }
}
