import { InventoryDTO } from '../dtos/inventory.dto';

export class InventoryEntity {
    readonly value: number;

    constructor(entity: InventoryEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: InventoryDTO) {
        return new InventoryEntity({
            value: dto.value,
        });
    }

    static toDTO(entity: InventoryEntity): InventoryDTO {
        return {
            value: entity.value,
        };
    }
}
