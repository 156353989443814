import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, User } from 'firebase/auth';
import { IUserLoginAuthDataSource } from './interfaces';

export class FbUserLoginDataSource implements IUserLoginAuthDataSource {
    private static instance: FbUserLoginDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new FbUserLoginDataSource();
        }
        return this.instance;
    }

    async login(username: string, password: string): Promise<User> {
        await signInWithEmailAndPassword(getAuth(), username, password);
        return await getAuth().currentUser;
    }

    async resetPassword(username: string): Promise<void> {
        await sendPasswordResetEmail(getAuth(), username);
    }
}
