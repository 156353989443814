import { OkrCategoryDTO } from '../dtos/okr/okr.category.dto';
import { OkrCategoryTypeDTO } from '../dtos/okr/okr.categoryType.dto';
import { OkrObjectiveEntity } from './okr.objective.entity';

export class OkrCategoryEntity {
    readonly id?: string;
    readonly categoryType: OkrCategoryTypeDTO;
    readonly objectiveList?: OkrObjectiveEntity[];
    progress?: number;

    constructor(entity: OkrCategoryEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: OkrCategoryDTO) {
        return new OkrCategoryEntity({
            id: dto.id,
            categoryType: dto.categoryType,
            progress: dto.progress,
            objectiveList: dto.objectiveList ? dto.objectiveList.map(el => OkrObjectiveEntity.fromDTO(el)) : [],
        });
    }

    static toDTO(entity: OkrCategoryEntity): OkrCategoryDTO {
        return {
            id: entity.id,
            categoryType: entity.categoryType,
            progress: entity.progress,
            objectiveList: entity.objectiveList ? entity.objectiveList.map(el => OkrObjectiveEntity.toDTO(el)) : [],
        };
    }
}
