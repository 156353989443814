export enum DurationType {
    DAY,
    WEEK,
    MONTH,
    QUARTER,
    YEAR,
}

export function fromDTO(value: string): DurationType {
    switch (value) {
        case 'DAY':
            return DurationType.DAY;
        case 'WEEK':
            return DurationType.WEEK;
        case 'MONTH':
            return DurationType.MONTH;
        case 'QUARTER':
            return DurationType.QUARTER;
        case 'YEAR':
            return DurationType.YEAR;
        default:
            return null;
    }
}

// const dayNames = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
const monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
];

export function getChartLabels(durationType: DurationType, startDate: Date, endDate: Date): string[] {
    let arr: string[] = [];
    let duration = 0;

    switch (durationType) {
        case DurationType.DAY:
            duration = Math.ceil(Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));

            while (arr.length <= duration) {
                arr.push(startDate.toLocaleDateString('de'));
                startDate.setDate(startDate.getDate() + 1);
            }
            return arr;
        case DurationType.WEEK:
            const oneWeek = 1000 * 60 * 60 * 24 * 7; // milliseconds in a week

            let startOfYear = new Date(startDate.getFullYear(), 0, 1);
            let diff = startDate.getTime() - startOfYear.getTime();
            let weekNumber = Math.floor(diff / oneWeek) + 1;

            startOfYear = new Date(endDate.getFullYear(), 0, 1);
            diff = endDate.getTime() - startOfYear.getTime();
            const weekNumberEnd = Math.floor(diff / oneWeek) + 1;

            duration = (endDate.getFullYear() - startDate.getFullYear()) * 52 + (weekNumberEnd - weekNumber);

            while (arr.length < duration) {
                startOfYear = new Date(startDate.getFullYear(), 0, 1);
                diff = startDate.getTime() - startOfYear.getTime();
                weekNumber = Math.floor(diff / oneWeek) + 1;
                let str = 'KW ' + weekNumber.toString().padStart(2, '0');
                arr.push(str + ` (${startDate.getFullYear()})`);
                startDate.setDate(startDate.getDate() + 7);
            }
            return arr;
        case DurationType.MONTH:
            duration =
                (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
            while (arr.length <= duration) {
                arr.push(monthNames[startDate.getMonth()] + ` (${startDate.getFullYear()})`);
                startDate.setMonth(startDate.getMonth() + 1);
            }
            return arr;
        case DurationType.QUARTER:
            duration =
                Math.floor(
                    ((endDate.getFullYear() - startDate.getFullYear()) * 12 +
                        (endDate.getMonth() - startDate.getMonth())) /
                        3,
                ) + 1; // Range: 1-4
            while (arr.length < duration) {
                let quarterValue = Math.floor((startDate.getMonth() + 1) / 3) + 1;
                arr.push(`Q${quarterValue} (${startDate.getFullYear()})`);
                startDate.setMonth(startDate.getMonth() + 3);
            }
            return arr;
        case DurationType.YEAR:
            duration = endDate.getFullYear() - startDate.getFullYear();
            while (arr.length <= duration) {
                arr.push(startDate.getFullYear().toString());
                startDate.setFullYear(startDate.getFullYear() + 1);
            }
            return arr;
        default:
            arr = [`Keine Zuordnung: ${durationType} - ${startDate} - ${endDate}`];
            return arr;
    }
}

export function calcStartDate(durationType: DurationType, endDate: Date): Date {
    let startDate = new Date(endDate);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    switch (durationType) {
        case DurationType.DAY:
            startDate.setDate(startDate.getDate() - 7);
            break;
        case DurationType.WEEK:
            startDate.setDate(startDate.getDate() - 35);
            while (startDate.getDay() !== 1) {
                startDate.setDate(startDate.getDate() - 1);
            }
            break;
        case DurationType.MONTH:
            startDate.setMonth(startDate.getMonth() - 12);
            startDate.setDate(1);
            break;
        case DurationType.QUARTER:
            startDate.setDate(1);
            startDate.setMonth(startDate.getMonth() - 24);
            while (startDate.getMonth() % 4 !== 0) {
                startDate.setMonth(startDate.getMonth() - 1);
            }
            break;
        case DurationType.YEAR:
            startDate.setFullYear(startDate.getFullYear() - 3);
            startDate.setDate(1);
            startDate.setMonth(0);
            break;
        default:
            startDate.setDate(1);
            startDate.setMonth(0);
            break;
    }
    return startDate;
}

export function calcEndDate(): Date {
    let endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(0);
    return endDate;
}
