import { RevenueAnalysisDTO } from '../../../core/dtos/revenue.analysis.dto';
import { RevenueAnalysisEntity } from '../../../core/entities/revenue.analysis.entity';
import { IAnalysisRevenueDataSource } from './data-sources/interfaces';
import { AnalysisRevenueDataSource } from './data-sources/load.revenue.data.source';

export class AnalysisRevenueUseCase {
    private static instance: AnalysisRevenueUseCase | null = null;

    private dataSource: IAnalysisRevenueDataSource;

    private constructor() {
        this.dataSource = AnalysisRevenueDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new AnalysisRevenueUseCase();
        }
        return this.instance;
    }

    async getAnalysisRevenue(entity: RevenueAnalysisEntity): Promise<RevenueAnalysisEntity> {
        let dto: RevenueAnalysisDTO = RevenueAnalysisEntity.toDTO(entity);
        dto = await this.dataSource.postAnalysisRevenue(dto);
        return RevenueAnalysisEntity.fromDTO(dto);
    }
}
