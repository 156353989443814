import { IUdpUserDataSource } from './data-sources/interfaces';
import { UdpUserDataSource } from './data-sources/udp.user.data.source';
import { UdpUserEntity } from '../../../core/entities/udp.user.entity';
import { User } from 'firebase/auth';
import { UdpUserDTO } from '../../../core/dtos/udp.user.dto';

export class UdpUserUseCase {
    private static instance: UdpUserUseCase | null = null;

    private dataSource: IUdpUserDataSource;

    private constructor() {
        this.dataSource = UdpUserDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new UdpUserUseCase();
        }
        return this.instance;
    }

    async loginUser(fbUser: User): Promise<UdpUserEntity> {
        let dto = UdpUserEntity.fromFbUser(fbUser);
        dto = await this.dataSource.loginUser(dto);
        return UdpUserEntity.fromDTO(dto);
    }

    async updateUser(entity: UdpUserDTO): Promise<UdpUserEntity> {
        let dto = UdpUserEntity.toDTO(entity);
        dto = await this.dataSource.updateUser(dto);
        return UdpUserEntity.fromDTO(dto);
    }

    async getAllUsers(): Promise<UdpUserEntity[]> {
        const list = await this.dataSource.getAllUsers();
        return list.map(el => UdpUserEntity.fromDTO(el));
    }
}
