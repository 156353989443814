import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { customerLoadDetails, customerSearchByFilter } from '../../../state/screens/customer/customerAsyncThunk';
import { selectCustomerList, selectSynced } from '../../../state/screens/customer/customerSlice';
import './CustomerSearchScreen.scss';
import { format } from 'date-fns';

interface CustomerSearchScreenProps {}

export function CustomerSearchScreen(props: CustomerSearchScreenProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [searchEmail, setSearchEmail] = useState('');
    const [searchFirstName, setSearchFirstName] = useState('');
    const [searchLastName, setSearchLastName] = useState('');

    const customerList = useAppSelector(selectCustomerList);
    const synced = useAppSelector(selectSynced);

    const searchCustomers = () => {
        dispatch(customerSearchByFilter({ email: searchEmail, firstName: searchFirstName, lastName: searchLastName }));
    };

    const selectCustomer = (customerId: string) => {
        dispatch(customerLoadDetails(customerId)).then((response: any) => {
            if (response?.payload?.id) {
                navigate(`/customers/${response?.payload?.id}`);
            }
        });
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            searchCustomers();
        }
    };

    function convertToLocalDateString(dateStr: string): string {
        return `${format(new Date(new Date(dateStr).getTime()), "dd.MM.yyyy'\n'HH:mm:ss")} Uhr`;
    }

    return (
        <>
            <Form className="search-form" onKeyDown={handleKeyPress}>
                <Form.Group className="search-form-input" controlId="formSearchEmail">
                    <Form.Label>{t('customer.email')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('customer.email')}
                        value={searchEmail}
                        onChange={e => setSearchEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="search-form-input" controlId="formSearchFirstName">
                    <Form.Label>{t('customer.first-name')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('customer.first-name')}
                        value={searchFirstName}
                        onChange={e => setSearchFirstName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="search-form-input" controlId="formSearchLastName">
                    <Form.Label>{t('customer.last-name')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('customer.last-name')}
                        value={searchLastName}
                        onChange={e => setSearchLastName(e.target.value)}
                    />
                </Form.Group>
                <Button className="search-btn" variant="primary" onClick={searchCustomers}>
                    {t('general.search')}
                </Button>
            </Form>
            {synced && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{'CDP'}</th>
                            <th>{'App'}</th>
                            <th>{'Billbee'}</th>
                            <th>{'CMS'}</th>
                            <th>{'Omnisend'}</th>
                            <th>{'Shopify'}</th>
                            <th>{'Zendesk'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{convertToLocalDateString(synced.cdp)}</td>
                            <td>{convertToLocalDateString(synced.app)}</td>
                            <td>{convertToLocalDateString(synced.billbee)}</td>
                            <td>{convertToLocalDateString(synced.cms)}</td>
                            <td>{convertToLocalDateString(synced.omnisend)}</td>
                            <td>{convertToLocalDateString(synced.shopify)}</td>
                            <td>{convertToLocalDateString(synced.zendesk)}</td>
                        </tr>
                    </tbody>
                </Table>
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{t('customer.email')}</th>
                        <th>{t('customer.first-name')}</th>
                        <th>{t('customer.last-name')}</th>
                    </tr>
                </thead>
                <tbody>
                    {customerList.map(item => (
                        <tr
                            key={item.id}
                            onClick={() => {
                                selectCustomer(item.id);
                            }}
                            style={{ cursor: 'pointer' }}>
                            <td>{item.app.email}</td>
                            <td>{item.app.firstName}</td>
                            <td>{item.app.lastName}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}
