import { backendAxios } from '../../../../core/axios';
import { OkrCreatePeriodDTO, OkrPeriodDTO } from '../../../../core/dtos/okr/okr.period.dto';
import { IOkrPeriodDataSource } from './interfaces';

export class OkrPeriodDataSource implements IOkrPeriodDataSource {
    private static instance: OkrPeriodDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new OkrPeriodDataSource();
        }
        return this.instance;
    }

    async getPeriod(periodId: string): Promise<OkrPeriodDTO> {
        try {
            const response = await backendAxios.get<OkrPeriodDTO>(`/okr/period/${periodId}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async getAllPeriods(): Promise<OkrPeriodDTO[]> {
        try {
            const response = await backendAxios.get<OkrPeriodDTO[]>(`/okr/period`, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async postCreatePeriod(dto: OkrCreatePeriodDTO): Promise<OkrPeriodDTO> {
        try {
            const response = await backendAxios.post<OkrPeriodDTO>(`/okr/period`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async putUpdatePeriod(dto: OkrPeriodDTO): Promise<OkrPeriodDTO> {
        try {
            const response = await backendAxios.put<OkrPeriodDTO>(`/okr/period`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
