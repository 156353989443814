import { OkrObjectiveEntity } from '../../../core/entities/okr.objective.entity';
import { IObjectiveDataSource } from './data-sources/interfaces';
import { ObjectiveDataSource } from './data-sources/okr.objective.data.source';

export class OkrObjectiveUseCase {
    private static instance: OkrObjectiveUseCase | null = null;
    private dataSourceObjective: IObjectiveDataSource;

    private constructor() {
        this.dataSourceObjective = ObjectiveDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new OkrObjectiveUseCase();
        }
        return this.instance;
    }

    async createObjective(entity: OkrObjectiveEntity): Promise<OkrObjectiveEntity> {
        let dto = OkrObjectiveEntity.toDTO(entity);
        dto = await this.dataSourceObjective.postCreateObjective(dto);
        return OkrObjectiveEntity.fromDTO(dto);
    }

    async updateObjective(entity: OkrObjectiveEntity): Promise<OkrObjectiveEntity> {
        let dto = OkrObjectiveEntity.toDTO(entity);
        dto = await this.dataSourceObjective.putUpdateObjective(dto);
        return OkrObjectiveEntity.fromDTO(dto);
    }

    async deleteObjective(id: string): Promise<{ id: string }> {
        await this.dataSourceObjective.deleteObjective(id);
        return { id };
    }
}
