import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserLoginUseCase } from '../../../features/user/login/user.login.use.case';
import { UserLogoutUseCase } from '../../../features/user/logout/user.logout.use.case';
import { UdpUserUseCase } from '../../../features/user/backend/udp.user.use.case';
import { UdpUserEntity } from '../../../core/entities/udp.user.entity';
import { User } from 'firebase/auth';

export const logoutUserAPI = createAsyncThunk('udpUser/logout', async () => {
    const useCase = UserLogoutUseCase.getInstance();
    useCase.logout();
    return;
});

export const loginUserAPI = createAsyncThunk(
    'udpUser/login',
    async ({ email, password }: { email: string; password: string }) => {
        const useCaseLogin = UserLoginUseCase.getInstance();
        let user = await useCaseLogin.login(email, password);

        const useCaseLoginBackend = UdpUserUseCase.getInstance();
        return useCaseLoginBackend.loginUser(user);
    },
);

export const alreadyLoggedInUserAPI = createAsyncThunk('udpUser/login', async (user: User) => {
    const useCaseLoginBackend = UdpUserUseCase.getInstance();
    return useCaseLoginBackend.loginUser(user);
});

export const resetPasswordUserAPI = createAsyncThunk('udpUser/login', async ({ email }: { email: string }) => {
    const useCaseLogin = UserLoginUseCase.getInstance();
    await useCaseLogin.resetPassword(email);
});

export const loadAllUsers = createAsyncThunk('udpUsers/getAllUsers', async () => {
    const useCase = UdpUserUseCase.getInstance();
    return useCase.getAllUsers();
});

export const updateUserAPI = createAsyncThunk('udpUsers/update', async (entity: UdpUserEntity) => {
    const useCase = UdpUserUseCase.getInstance();
    return await useCase.updateUser(entity);
});
