import { JSONTree } from 'react-json-tree';
import { OmnisendCustomer } from '../../../core/dtos/customer.dto';
import './OmnisendCustomerComponent.scss';

interface OmnisendCustomerComponentProps {
    customer: OmnisendCustomer;
}

export function OmnisendCustomerComponent(props: OmnisendCustomerComponentProps) {
    return <>{props.customer && <JSONTree data={props.customer} />}</>;
}
