import { OkrCategoryTypeDTO, OkrCreateCategoryTypeDTO } from '../../../core/dtos/okr/okr.categoryType.dto';
import { IOkrCategoryTypeDataSource } from './data-sources/interfaces';
import { OkrCategoryTypeDataSource } from './data-sources/okr.categoryType.data.source';

export class OkrCategoryTypeUseCase {
    private static instance: OkrCategoryTypeUseCase | null = null;
    private dataSourceOkrCategoryType: IOkrCategoryTypeDataSource;

    private constructor() {
        this.dataSourceOkrCategoryType = OkrCategoryTypeDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new OkrCategoryTypeUseCase();
        }
        return this.instance;
    }

    async getAllCategoryTypes(): Promise<OkrCategoryTypeDTO[]> {
        const dtoList = await this.dataSourceOkrCategoryType.getAllCategoryTypes();
        return dtoList;
    }

    async createCategoryType(dto: OkrCreateCategoryTypeDTO): Promise<OkrCategoryTypeDTO> {
        const response = await this.dataSourceOkrCategoryType.postCreateCategoryType(dto);
        return response;
    }

    async updateCategoryType(dto: OkrCategoryTypeDTO): Promise<OkrCategoryTypeDTO> {
        dto = await this.dataSourceOkrCategoryType.putUpdateCategoryType(dto);
        return dto;
    }
}
