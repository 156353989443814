import { firebaseConfig } from './firebase-config';
import { initializeApp } from 'firebase/app';
import { Route, Routes } from 'react-router';
import LoginScreen from './screens/authentication/LoginScreen';
import { CustomerSearchScreen } from './screens/customers/CustomerSearchScreen/CustomerSearchScreen';
import { CustomerOverviewScreen } from './screens/customers/CustomerOverviewScreen/CustomerOverviewScreen';
import { DashboardScreen } from './screens/dashboard/DashboardScreen';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { selectUdpUser, selectUdpUserIsLoading } from './state/screens/user/userSlice';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { NavigationWrapper } from './screens/NavigationWrapper/NavigationWrapper';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    Filler,
} from 'chart.js';
import { OkrScreen } from './screens/okr/OkrScreen';
import { alreadyLoggedInUserAPI } from './state/screens/user/userAsyncThunks';

function App() {
    // Check ENV
    const { REACT_APP_VERSION, REACT_APP_MODE, REACT_APP_BACKEND_URL, REACT_APP_AUTH_METHOD } = process.env;
    console.log('MODE: ', REACT_APP_MODE);
    console.log('BE: ', REACT_APP_BACKEND_URL);
    console.log('AUTH: ', REACT_APP_AUTH_METHOD);

    // Initialize Firebase
    initializeApp(firebaseConfig);

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUdpUser);
    const isLoading = useAppSelector(selectUdpUserIsLoading);

    // ChartJS
    ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Filler, Title, Tooltip, Legend);

    useEffect(() => {
        getAuth().onAuthStateChanged(u => {
            if (u) {
                dispatch(alreadyLoggedInUserAPI(u));
            }
        });
    }, [dispatch]);

    return (
        <>
            {!user && !isLoading && <LoginScreen />}
            {user && <NavigationWrapper appVersion={REACT_APP_VERSION} />}
            <Routes>
                {!user && (
                    <>
                        <Route path="/" element={<LoginScreen />} />
                    </>
                )}
                {user && (
                    <>
                        <Route path="customers/:id" element={<CustomerOverviewScreen />} />
                        <Route path="customers" element={<CustomerSearchScreen />} />
                        <Route path="kpi" element={<DashboardScreen />} />
                        <Route path="okr" element={<OkrScreen />} />
                        <Route path="/" element={<OkrScreen />} />
                    </>
                )}
            </Routes>
        </>
    );
}

export default App;
