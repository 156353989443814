export function convertDateToDDMMYYYY(input: Date): string {
    let day = input.getDate().toString();
    let month = (input.getMonth() + 1).toString();
    const year = input.getFullYear().toString();

    if (day.length === 1) {
        day = '0' + day;
    }
    if (month.length === 1) {
        month = '0' + month;
    }

    return `${day}.${month}.${year}`;
}

export function censorWord(str: string) {
    if (str.length >= 3) {
        return str[0] + '*'.repeat(str.length - 2) + str.slice(-1);
    } else if (str.length === 2) {
        return str[0] + '*';
    } else {
        return '*';
    }
}

export function censorEmail(email: string) {
    var arr = email.split('@');
    return censorWord(arr[0]) + '@' + censorWord(arr[1]);
}

export function deepCopyArray<T>(array: T[]): T[] {
    return JSON.parse(JSON.stringify(array));
    // return array.map(a => {
    //     return { ...a };
    // });
}

export function getChartColor(idx: number, isBackground: boolean) {
    switch (idx) {
        case 0:
            return isBackground ? 'rgba(64, 212, 113, 0.5)' : 'rgb(64, 212, 113)';
        case 1:
            return isBackground ? 'rgba(255, 206, 86, 0.5)' : 'rgb(255, 206, 86)';
        case 2:
            return isBackground ? 'rgba(53, 162, 235, 0.5)' : 'rgb(53, 162, 235)';
        case 3:
            return isBackground ? 'rgba(255, 99, 132, 0.5)' : 'rgb(255, 99, 132)';
        case 4:
            return isBackground ? 'rgba(153, 102, 255, 0.5)' : 'rgb(153, 102, 255)';
        case 5:
            return isBackground ? 'rgba(255, 159, 64, 0.5)' : 'rgb(255, 159, 64)';
        default:
            return isBackground ? 'rgba(0, 0, 0, 0.5)' : 'rgb(0, 0, 0)';
    }
}

export function formatToPercent(value: number): string {
    return (Math.round(value * 100) / 100).toFixed(0) + ' %';
}

export function getOkrProgressColor(value: number): string {
    if (value >= 80) {
        return 'green';
    } else if (value >= 40) {
        return 'orange';
    } else {
        return 'red';
    }
}
