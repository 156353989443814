import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
    createCategoryOKR,
    createCategoryTypeOKR,
    createKeyResultOKR,
    createObjectiveOKR,
    createPeriodOKR,
    deleteKeyResultOKR,
    deleteObjectiveOKR,
    loadAllCategoryTypesOKR,
    loadAllPeriodsOKR,
    loadPeriodOKR,
    updateCategoryOKR,
    updateCategoryTypeOKR,
    updateKeyResultOKR,
    updateObjectiveOKR,
    updatePeriodOKR,
} from './okrAsyncThunk';
import { OkrCategoryTypeDTO } from '../../../core/dtos/okr/okr.categoryType.dto';
import { OkrPeriodEntity } from '../../../core/entities/okr.period.entity';
import { OkrCategoryEntity } from '../../../core/entities/okr.category.entity';
import { OkrObjectiveEntity } from '../../../core/entities/okr.objective.entity';

export interface OkrState {
    selectedPeriod: OkrPeriodEntity;
    periodList: OkrPeriodEntity[];
    categoryTypeList: OkrCategoryTypeDTO[];
    errorCode?: string;
    isLoading: boolean;
}

const initialState: OkrState = {
    selectedPeriod: undefined,
    periodList: [],
    categoryTypeList: [],
    errorCode: undefined,
    isLoading: false,
};

function calcProgress(period: OkrPeriodEntity): OkrPeriodEntity {
    let progress = 0;
    period.categoryList.forEach(category => {
        category.progress = calcProgressCategory(category);
        progress += category.progress;
    });
    period.progress = progress / getDivisor(period.categoryList.length);
    return period;
}

function calcProgressCategory(category: OkrCategoryEntity): number {
    let progress = 0;
    let weightSum: number = 0;
    category.objectiveList.forEach(objective => {
        objective.progress = calcProgressObjective(objective);
        weightSum += objective.weight;
        progress += objective.progress * objective.weight;
    });
    progress = progress / getDivisor(weightSum);
    return progress;
}

function calcProgressObjective(objective: OkrObjectiveEntity): number {
    let progress = 0;
    let weightSum: number = 0;
    objective.keyResultList.forEach(keyResult => {
        weightSum += keyResult.weight;
        progress += keyResult.progress * keyResult.weight;
    });
    progress = progress / getDivisor(weightSum);
    return progress;
}

function getDivisor(value: number): number {
    return value > 0 ? value : 1;
}

export const okrSlice = createSlice({
    name: 'okrSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadPeriodOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(loadPeriodOKR.fulfilled, (state, action) => {
                state.selectedPeriod = calcProgress(action.payload);
                state.isLoading = false;
            })
            .addCase(loadPeriodOKR.rejected, (state, action) => {
                console.error('loadPeriodOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(loadAllPeriodsOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(loadAllPeriodsOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                state.periodList = action.payload;
            })
            .addCase(loadAllPeriodsOKR.rejected, (state, action) => {
                console.error('loadAllPeriodsOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(createPeriodOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(createPeriodOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedPeriod = calcProgress(action.payload);
            })
            .addCase(createPeriodOKR.rejected, (state, action) => {
                console.error('createPeriodOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(updatePeriodOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(updatePeriodOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedPeriod = calcProgress(action.payload);
            })
            .addCase(updatePeriodOKR.rejected, (state, action) => {
                console.error('updatePeriodOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(createCategoryOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(createCategoryOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedPeriod.categoryList.push(action.payload);
                state.selectedPeriod = calcProgress(state.selectedPeriod);
            })
            .addCase(createCategoryOKR.rejected, (state, action) => {
                console.error('createCategoryOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(updateCategoryOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(updateCategoryOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                const idx = state.selectedPeriod.categoryList.findIndex(el => el.id === action.payload.id);
                if (idx >= 0) {
                    state.selectedPeriod.categoryList[idx] = action.payload;
                }
                state.selectedPeriod = calcProgress(state.selectedPeriod);
            })
            .addCase(updateCategoryOKR.rejected, (state, action) => {
                console.error('updateCategoryOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(createObjectiveOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(createObjectiveOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                const idx = state.selectedPeriod.categoryList.findIndex(el => el.id === action.payload.categoryId);
                if (idx >= 0) {
                    state.selectedPeriod.categoryList[idx].objectiveList.push(action.payload);
                }
                state.selectedPeriod = calcProgress(state.selectedPeriod);
            })
            .addCase(createObjectiveOKR.rejected, (state, action) => {
                console.error('createObjectiveOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(updateObjectiveOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(updateObjectiveOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                for (let i = 0; i < state.selectedPeriod.categoryList.length; i++) {
                    const idx = state.selectedPeriod.categoryList[i].objectiveList.findIndex(
                        el => el.id === action.payload.id,
                    );
                    if (idx >= 0) {
                        state.selectedPeriod.categoryList[i].objectiveList[idx] = action.payload;
                    }
                }
                state.selectedPeriod = calcProgress(state.selectedPeriod);
            })
            .addCase(updateObjectiveOKR.rejected, (state, action) => {
                console.error('updateObjectiveOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(deleteObjectiveOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(deleteObjectiveOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                for (let i = 0; i < state.selectedPeriod.categoryList.length; i++) {
                    const idx = state.selectedPeriod.categoryList[i].objectiveList.findIndex(
                        el => el.id === action.payload.id,
                    );
                    if (idx >= 0) {
                        state.selectedPeriod.categoryList[i].objectiveList.splice(idx, 1);
                    }
                }
                state.selectedPeriod = calcProgress(state.selectedPeriod);
            })
            .addCase(deleteObjectiveOKR.rejected, (state, action) => {
                console.error('deleteObjectiveOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(createKeyResultOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(createKeyResultOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                for (let i = 0; i < state.selectedPeriod.categoryList.length; i++) {
                    const idx = state.selectedPeriod.categoryList[i].objectiveList.findIndex(
                        el => el.id === action.payload.objectiveId,
                    );
                    if (idx >= 0) {
                        state.selectedPeriod.categoryList[i].objectiveList[idx].keyResultList.push(action.payload);
                    }
                }
                state.selectedPeriod = calcProgress(state.selectedPeriod);
            })
            .addCase(createKeyResultOKR.rejected, (state, action) => {
                console.error('createKeyResultOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(updateKeyResultOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(updateKeyResultOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                for (let i = 0; i < state.selectedPeriod.categoryList.length; i++) {
                    for (let j = 0; j < state.selectedPeriod.categoryList[i].objectiveList.length; j++) {
                        const idx = state.selectedPeriod.categoryList[i].objectiveList[j].keyResultList.findIndex(
                            el => el.id === action.payload.id,
                        );
                        if (idx >= 0) {
                            state.selectedPeriod.categoryList[i].objectiveList[j].keyResultList[idx] = action.payload;
                        }
                    }
                }
                state.selectedPeriod = calcProgress(state.selectedPeriod);
            })
            .addCase(updateKeyResultOKR.rejected, (state, action) => {
                console.error('updateKeyResultOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(deleteKeyResultOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(deleteKeyResultOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                for (let i = 0; i < state.selectedPeriod.categoryList.length; i++) {
                    for (let j = 0; j < state.selectedPeriod.categoryList[i].objectiveList.length; j++) {
                        const idx = state.selectedPeriod.categoryList[i].objectiveList[j].keyResultList.findIndex(
                            el => el.id === action.payload.id,
                        );
                        if (idx >= 0) {
                            state.selectedPeriod.categoryList[i].objectiveList[j].keyResultList.splice(idx, 1);
                        }
                    }
                }
                state.selectedPeriod = calcProgress(state.selectedPeriod);
            })
            .addCase(deleteKeyResultOKR.rejected, (state, action) => {
                console.error('deleteKeyResultOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(loadAllCategoryTypesOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(loadAllCategoryTypesOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                state.categoryTypeList = action.payload;
            })
            .addCase(loadAllCategoryTypesOKR.rejected, (state, action) => {
                console.error('loadAllCategoryTypesOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(createCategoryTypeOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(createCategoryTypeOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                state.categoryTypeList.push(action.payload);
            })
            .addCase(createCategoryTypeOKR.rejected, (state, action) => {
                console.error('createCategoryTypeOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(updateCategoryTypeOKR.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(updateCategoryTypeOKR.fulfilled, (state, action) => {
                state.isLoading = false;
                const idx = state.categoryTypeList.findIndex(el => el.id === action.payload.id);
                if (idx >= 0) {
                    state.categoryTypeList[idx] = action.payload;
                }
            })
            .addCase(updateCategoryTypeOKR.rejected, (state, action) => {
                console.error('updateCategoryTypeOKR', action.error);
                state.selectedPeriod = undefined;
                state.errorCode = action.error.code;
                state.isLoading = false;
            });
    },
});

export const selectIsLoadingOKR = (state: RootState) => state.okrSlice.isLoading;
export const selectPeriodOKR = (state: RootState) => state.okrSlice.selectedPeriod;
export const selectPeriodListOKR = (state: RootState) => state.okrSlice.periodList;
export const selectCategoryTypeListOKR = (state: RootState) => state.okrSlice.categoryTypeList;
export const selectCategoryListRecentPeriodOKR = (state: RootState) => state.okrSlice.selectedPeriod.categoryList;

export default okrSlice.reducer;
