import { ClvAnalysisDTO } from '../../../core/dtos/clv.analysis.dto';
import { ClvAnalysisEntity } from '../../../core/entities/clv.analysis.entity';
import { IAnalysisClvDataSource } from './data-sources/interfaces';
import { AnalysisClvDataSource } from './data-sources/load.clv.data.source';

export class AnalysisClvUseCase {
    private static instance: AnalysisClvUseCase | null = null;

    private dataSource: IAnalysisClvDataSource;

    private constructor() {
        this.dataSource = AnalysisClvDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new AnalysisClvUseCase();
        }
        return this.instance;
    }

    async getAnalysisClv(entity: ClvAnalysisEntity): Promise<ClvAnalysisEntity> {
        let dto: ClvAnalysisDTO = ClvAnalysisEntity.toDTO(entity);
        dto = await this.dataSource.postAnalysisClv(dto);
        return ClvAnalysisEntity.fromDTO(dto);
    }
}
