import { getAuth } from 'firebase/auth';
import { IUserLogoutDataSource } from './interfaces';

export class FbUserLogoutDataSource implements IUserLogoutDataSource {
    private static instance: FbUserLogoutDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new FbUserLogoutDataSource();
        }
        return this.instance;
    }

    async logout(): Promise<undefined> {
        await getAuth().signOut();
        return;
    }
}
