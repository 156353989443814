import { JSONTree } from 'react-json-tree';
import { AppCustomer } from '../../../core/dtos/customer.dto';
import './AppCustomerComponent.scss';

interface AppCustomerComponentProps {
    customer: AppCustomer;
}

export function AppCustomerComponent(props: AppCustomerComponentProps) {
    return <>{props.customer && <JSONTree data={props.customer} />}</>;
}
