import { backendAxios } from '../../../../core/axios';
import { InventoryAnalysisDTO } from '../../../../core/dtos/inventory.analysis.dto';
import { IAnalysisInventoryDataSource } from './interfaces';

export class AnalysisInventoryDataSource implements IAnalysisInventoryDataSource {
    private static instance: AnalysisInventoryDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new AnalysisInventoryDataSource();
        }
        return this.instance;
    }

    async postAnalysisInventory(dto: InventoryAnalysisDTO): Promise<InventoryAnalysisDTO> {
        try {
            const response = await backendAxios.post<InventoryAnalysisDTO>(`/accounting/inventory`, dto);
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
