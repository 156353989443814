import axios from 'axios';
import { getAuth } from 'firebase/auth';

// For common config
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

const backendAxios = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    timeout: 60 * 1000,
});

backendAxios.interceptors.request.use(async (config: any) => {
    try {
        const token = await getAuth().currentUser.getIdToken();
        if (config.headers) {
            config.headers.Authorization = token;
        } else {
            config.headers = { Authorization: token };
        }
        return config;
    } catch (e) {
        return config;
    }
});
backendAxios.interceptors.response.use(
    response => response,
    error => {
        console.error(error);
        return Promise.reject(error);
    },
);

export { backendAxios };
