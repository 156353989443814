import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClvAnalysisEntity } from '../../../core/entities/clv.analysis.entity';
import { InventoryAnalysisEntity } from '../../../core/entities/inventory.analysis.entity';
import { RevenueAnalysisEntity } from '../../../core/entities/revenue.analysis.entity';
import { AnalysisRevenueUseCase } from '../../../features/kpis/revenue/load.revenue.use.case';
import { AnalysisClvUseCase } from '../../../features/kpis/clv/load.clv.use.case';
import { AnalysisInventoryUseCase } from '../../../features/kpis/inventory/load.inventory.use.case';

export const getAnalysisRevenue = createAsyncThunk(
    'accountingScreen/analysisRevenue',
    async (entity: RevenueAnalysisEntity) => {
        const useCase = AnalysisRevenueUseCase.getInstance();
        return await useCase.getAnalysisRevenue(entity);
    },
);

export const getAnalysisClv = createAsyncThunk('accountingScreen/analysisClv', async (entity: ClvAnalysisEntity) => {
    const useCase = AnalysisClvUseCase.getInstance();
    return await useCase.getAnalysisClv(entity);
});

export const getAnalysisInventory = createAsyncThunk(
    'accountingScreen/analysisInventory',
    async (entity: InventoryAnalysisEntity) => {
        const useCase = AnalysisInventoryUseCase.getInstance();
        return await useCase.getAnalysisInventory(entity);
    },
);
