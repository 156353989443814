import { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { AppCustomerComponent } from '../../../components/customers/AppCustomerComponent/AppCustomerComponent';
import { BillbeeCustomerComponent } from '../../../components/customers/BillbeeCustomerComponent/BillbeeCustomerComponent';
import { OmnisendCustomerComponent } from '../../../components/customers/OmnisendCustomerComponent/OmnisendCustomerComponent';
import { ShopifyCustomerComponent } from '../../../components/customers/ShopifyCustomerComponent/ShopifyCustomerComponent';
import { ZendeskCustomerComponent } from '../../../components/customers/ZendeskCustomerComponent/ZendeskCustomerComponent';
import { CustomerDTO } from '../../../core/dtos/customer.dto';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { customerLoadDetails } from '../../../state/screens/customer/customerAsyncThunk';
import { selectSelectedCustomer } from '../../../state/screens/customer/customerSlice';
import './CustomerOverviewScreen.scss';

export function CustomerOverviewScreen() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const customer: CustomerDTO = useAppSelector(selectSelectedCustomer);

    useEffect(() => {
        if (!customer) {
            dispatch(customerLoadDetails(id));
        }
    }, [dispatch, customer, id]);

    return (
        <>
            {customer && (
                <Tabs defaultActiveKey="app" id="customer-tab" className="mb-3">
                    <Tab eventKey="app" title="App">
                        <AppCustomerComponent customer={customer.app} />
                    </Tab>
                    <Tab eventKey="bilbee" title="Billbee">
                        <BillbeeCustomerComponent customer={customer.billbee} />
                    </Tab>
                    <Tab eventKey="omnisend" title="Omnisend">
                        <OmnisendCustomerComponent customer={customer.omnisend} />
                    </Tab>
                    <Tab eventKey="shopify" title="Shopify">
                        <ShopifyCustomerComponent customer={customer.shopify} />
                    </Tab>
                    <Tab eventKey="zendesk" title="Zendesk">
                        <ZendeskCustomerComponent customer={customer.zendesk} />
                    </Tab>
                </Tabs>
            )}
        </>
    );
}
