import { User } from 'firebase/auth';
import { UdpUserDTO } from '../dtos/udp.user.dto';

export class UdpUserEntity {
    readonly uid: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly accessLevel: string;
    readonly okrColor: string;

    constructor(entity: UdpUserEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: UdpUserDTO) {
        return new UdpUserEntity({
            uid: dto.uid,
            email: dto.email,
            firstName: dto.firstName,
            lastName: dto.lastName,
            accessLevel: dto.accessLevel,
            okrColor: dto.okrColor,
        });
    }

    static toDTO(entity: UdpUserEntity): UdpUserDTO {
        return {
            uid: entity.uid,
            email: entity.email,
            firstName: entity.firstName,
            lastName: entity.lastName,
            accessLevel: entity.accessLevel,
            okrColor: entity.okrColor,
        };
    }

    static fromFbUser(fbUser: User): UdpUserDTO {
        return {
            uid: fbUser.uid,
            email: fbUser.email,
            firstName: undefined,
            lastName: undefined,
            accessLevel: undefined,
            okrColor: undefined,
        };
    }
}
