import { backendAxios } from '../../../../core/axios';
import { ClvAnalysisDTO } from '../../../../core/dtos/clv.analysis.dto';
import { IAnalysisClvDataSource } from './interfaces';

export class AnalysisClvDataSource implements IAnalysisClvDataSource {
    private static instance: AnalysisClvDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new AnalysisClvDataSource();
        }
        return this.instance;
    }

    async postAnalysisClv(dto: ClvAnalysisDTO): Promise<ClvAnalysisDTO> {
        try {
            const response = await backendAxios.post<ClvAnalysisDTO>(`/accounting/clv`, dto);
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
