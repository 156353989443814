import { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../state/hooks';
import { resetPasswordUserAPI, loginUserAPI } from '../../state/screens/user/userAsyncThunks';
import './LoginScreen.scss';
import logo from '../../assets/logo.png';

const LoginScreen = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        dispatch(loginUserAPI({ email: email, password: password })).then(() => {
            navigate('/okr');
        });
    };

    const handleResetPassword = () => {
        dispatch(resetPasswordUserAPI({ email: email })).then(() => {
            alert(`Email zum Zurücksetzen wurde an '${email}' gesendet.`);
        });
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="loginCardWrapper">
            <Card className="loginCard">
                <Card.Body>
                    <Card.Title style={{ marginBottom: '20px' }}>
                        <img src={logo} width="50" height="50" className="d-inline-block align-top" alt="" />
                        <span style={{ lineHeight: '50px', fontSize: '26px', fontWeight: 'bold' }}>
                            urbanhive Data Platform
                        </span>
                    </Card.Title>
                    <Form onKeyDown={handleKeyPress}>
                        <Form.Group className="loginInput" controlId="formEmail">
                            <Form.Label>{t('authentication.email')}</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={t('authentication.email')}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="loginInput" controlId="formPassword">
                            <Form.Label>{t('authentication.password')}</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('authentication.password')}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: '20px',
                            }}>
                            <Button
                                variant="secondary"
                                onClick={handleResetPassword}
                                style={{ border: '2px solid #40d471' }}>
                                {t('authentication.reset-password')}
                            </Button>
                            <Button variant="primary" onClick={handleLogin}>
                                {t('authentication.login')}
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default LoginScreen;
