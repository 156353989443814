import { DurationType, fromDTO } from '../dtos/duration.type';
import { format } from 'date-fns';
import { InventoryEntity } from './inventory.entity';
import { InventoryAnalysisDTO } from '../dtos/inventory.analysis.dto';

export class InventoryAnalysisEntity {
    readonly durationType: DurationType;
    readonly startDate: Date;
    readonly endDate: Date;
    readonly list?: InventoryEntity[][];

    constructor(entity: InventoryAnalysisEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: InventoryAnalysisDTO) {
        return new InventoryAnalysisEntity({
            durationType: fromDTO(dto.durationType.toString()),
            startDate: new Date(dto.startDate),
            endDate: new Date(dto.endDate),
            list: dto.list ? dto.list.map(el1 => el1.map(el2 => InventoryEntity.fromDTO(el2))) : [],
        });
    }

    static toDTO(entity: InventoryAnalysisEntity): InventoryAnalysisDTO {
        return {
            durationType: entity.durationType,
            startDate: format(entity.startDate, "yyyy-MM-dd'T'HH:mm:ss"),
            endDate: format(entity.endDate, "yyyy-MM-dd'T'HH:mm:ss"),
            list: entity.list ? entity.list.map(el1 => el1.map(el2 => InventoryEntity.toDTO(el2))) : [],
        };
    }
}
