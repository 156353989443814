import { FbUserLogoutDataSource } from './data-sources/fb.user.logout.data.source';
import { IUserLogoutDataSource } from './data-sources/interfaces';

export class UserLogoutUseCase {
    private static instance: UserLogoutUseCase | null = null;

    private dataSource: IUserLogoutDataSource;

    private constructor() {
        this.dataSource = FbUserLogoutDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new UserLogoutUseCase();
        }
        return this.instance;
    }

    async logout(): Promise<undefined> {
        return this.dataSource.logout();
    }
}
