import './IconButtonMenu.scss';
import * as Icon from 'react-bootstrap-icons';
import { Dropdown } from 'react-bootstrap';

interface Props {
    onEdit?: () => any;
    onDelete?: () => any;
    onAdd?: () => any;
    addText?: string;
}

export function IconButtonMenu(props: Props) {
    return (
        <>
            <Dropdown>
                <Dropdown.Toggle className="menu-toggle-btn">
                    <Icon.ThreeDotsVertical className="icon-btn-icon" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {props.addText && props.onAdd && (
                        <Dropdown.Item onClick={props.onAdd}>
                            <Icon.PlusCircle className="icon-btn-icon icon-dropdown" />
                            {props.addText}
                        </Dropdown.Item>
                    )}
                    {props.onEdit && (
                        <Dropdown.Item onClick={props.onEdit}>
                            <Icon.PencilFill className="icon-btn-icon icon-dropdown" />
                            Bearbeiten
                        </Dropdown.Item>
                    )}
                    {props.onDelete && (
                        <Dropdown.Item onClick={props.onDelete}>
                            <Icon.Trash3Fill className="icon-btn-icon icon-dropdown" />
                            Löschen
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}
