import { DurationType, fromDTO } from '../dtos/duration.type';
import { format } from 'date-fns';
import { ClvAnalysisDTO } from '../dtos/clv.analysis.dto';
import { ClvEntity } from './clv.entity';

export class ClvAnalysisEntity {
    readonly durationType: DurationType;
    readonly startDate: Date;
    readonly endDate: Date;
    readonly expectedYears: number;
    readonly list?: ClvEntity[][];

    constructor(entity: ClvAnalysisEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: ClvAnalysisDTO) {
        return new ClvAnalysisEntity({
            durationType: fromDTO(dto.durationType.toString()),
            startDate: new Date(dto.startDate),
            endDate: new Date(dto.endDate),
            expectedYears: dto.expectedYears,
            list: dto.list ? dto.list.map(el1 => el1.map(el2 => ClvEntity.fromDTO(el2))) : [],
        });
    }

    static toDTO(entity: ClvAnalysisEntity): ClvAnalysisDTO {
        return {
            durationType: entity.durationType,
            startDate: format(entity.startDate, "yyyy-MM-dd'T'HH:mm:ss"),
            endDate: format(entity.endDate, "yyyy-MM-dd'T'HH:mm:ss"),
            expectedYears: entity.expectedYears,
            list: entity.list ? entity.list.map(el1 => el1.map(el2 => ClvEntity.toDTO(el2))) : [],
        };
    }
}
