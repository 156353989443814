import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../state/hooks';
import { logoutUserAPI } from '../../state/screens/user/userAsyncThunks';
import './NavigationWrapper.scss';
import logo from '../../assets/logo.png';

interface NavigationWrapperProps {
    appVersion: string;
}

export function NavigationWrapper(props: NavigationWrapperProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <>
            <Navbar className="mainNavbar" collapseOnSelect expand="lg" style={{ backgroundColor: '#40d471' }}>
                <Navbar.Brand>
                    <img src={logo} width="50" height="50" className="d-inline-block align-top" alt="" />
                    <span style={{ lineHeight: '50px', fontSize: '22px', fontWeight: 'bold' }}>
                        urbanhive Data Platform
                    </span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className="mr-auto">
                        <Nav.Link onClick={() => navigate('/okr')}>OKRs</Nav.Link>
                        <Nav.Link onClick={() => navigate('/kpi')}>Dashboard</Nav.Link>
                        <Nav.Link onClick={() => navigate('/customers')}>Customer Search</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Link
                            onClick={() => {
                                dispatch(logoutUserAPI()).then(() => {
                                    navigate('/');
                                });
                            }}>
                            {t('authentication.logout')}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Text className="app-version">({props.appVersion})</Navbar.Text>
            </Navbar>
        </>
    );
}
