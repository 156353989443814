import { createSlice } from '@reduxjs/toolkit';
import { getAnalysisTypeString } from '../../../core/dtos/analysis.type';
import { ClvChartDTO } from '../../../core/dtos/clv.chart.dto';
import { getChartLabels } from '../../../core/dtos/duration.type';
import { InventoryChartDTO } from '../../../core/dtos/inventory.chart.dto';
import { getInventoryTypeString } from '../../../core/dtos/product.inventory.type';
import { RevenueChartDTO } from '../../../core/dtos/revenue.chart.dto';
import { ClvAnalysisEntity } from '../../../core/entities/clv.analysis.entity';
import { InventoryAnalysisEntity } from '../../../core/entities/inventory.analysis.entity';
import { RevenueAnalysisEntity } from '../../../core/entities/revenue.analysis.entity';
import { getChartColor } from '../../../core/utils';
import { RootState } from '../../store';
import { getAnalysisClv, getAnalysisInventory, getAnalysisRevenue } from './accountingAsyncThunk';

export interface AccountingState {
    errorCode?: string;
    isLoading: boolean;
    revenueChart: RevenueChartDTO;
    clvChart: ClvChartDTO;
    inventoryChart: InventoryChartDTO;
}

const initialState: AccountingState = {
    errorCode: undefined,
    isLoading: false,
    revenueChart: {
        revenueData: undefined,
        aovData: undefined,
    },
    clvChart: {
        clvData: undefined,
        flvData: undefined,
    },
    inventoryChart: {
        inventoryData: undefined,
    },
};

export const accountingSlice = createSlice({
    name: 'accountingSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAnalysisRevenue.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(getAnalysisRevenue.fulfilled, (state, action) => {
                let entity: RevenueAnalysisEntity = action.payload;
                let chartLabels = getChartLabels(entity.durationType, entity.startDate, entity.endDate);

                state.revenueChart = {
                    revenueData: {
                        labels: chartLabels,
                        datasets: entity.list.map((list, idx) => {
                            return {
                                label: getAnalysisTypeString(idx),
                                borderColor: getChartColor(idx, false),
                                backgroundColor: getChartColor(idx, true),
                                data: list.map(dto => dto.revenue),
                            };
                        }),
                    },
                    aovData: {
                        labels: chartLabels,
                        datasets: entity.list.map((list, idx) => {
                            return {
                                label: getAnalysisTypeString(idx),
                                borderColor: getChartColor(idx, false),
                                backgroundColor: getChartColor(idx, true),
                                data: list.map(dto => dto.aov),
                            };
                        }),
                    },
                };
                // state.isLoading = false;
            })
            .addCase(getAnalysisRevenue.rejected, (state, action) => {
                console.error('getAnalysisRevenue', action.error);
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(getAnalysisClv.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(getAnalysisClv.fulfilled, (state, action) => {
                let entity: ClvAnalysisEntity = action.payload;
                let chartLabels = getChartLabels(entity.durationType, entity.startDate, entity.endDate);

                state.clvChart = {
                    clvData: {
                        labels: chartLabels,
                        datasets: entity.list.map((list, idx) => {
                            return {
                                fill: true,
                                label: getAnalysisTypeString(idx),
                                borderColor: getChartColor(idx, false),
                                backgroundColor: getChartColor(idx, true),
                                data: list.map(dto => dto.clv),
                            };
                        }),
                    },
                    flvData: {
                        labels: chartLabels,
                        datasets: entity.list.map((list, idx) => {
                            return {
                                fill: true,
                                label: getAnalysisTypeString(idx),
                                borderColor: getChartColor(idx, false),
                                backgroundColor: getChartColor(idx, true),
                                data: list.map(dto => dto.flv),
                            };
                        }),
                    },
                };
                // state.isLoading = false;
            })
            .addCase(getAnalysisClv.rejected, (state, action) => {
                console.error('getAnalysisClv', action.error);
                state.errorCode = action.error.code;
                state.isLoading = false;
            })
            .addCase(getAnalysisInventory.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(getAnalysisInventory.fulfilled, (state, action) => {
                let entity: InventoryAnalysisEntity = action.payload;
                let chartLabels = getChartLabels(entity.durationType, entity.startDate, entity.endDate);

                state.inventoryChart = {
                    inventoryData: {
                        labels: chartLabels,
                        datasets: entity.list.map((list, idx) => {
                            return {
                                label: getInventoryTypeString(idx),
                                yAxisID: idx === 1 || idx === 2 ? 'y2' : 'y1',
                                borderColor: getChartColor(idx, false),
                                backgroundColor: getChartColor(idx, true),
                                data: list.map(dto => dto.value),
                            };
                        }),
                    },
                };
                state.isLoading = false;
            })
            .addCase(getAnalysisInventory.rejected, (state, action) => {
                console.error('getAnalysisInventory', action.error);
                state.errorCode = action.error.code;
                state.isLoading = false;
            });
    },
});

export const selectAccountingIsLoading = (state: RootState) => state.accountingSlice.isLoading;
export const selectAccountingErrorCode = (state: RootState) => state.accountingSlice.errorCode;
export const selectAccountingRevenueChart = (state: RootState) => state.accountingSlice.revenueChart;
export const selectAccountingClvChart = (state: RootState) => state.accountingSlice.clvChart;
export const selectAccountingInventoryChart = (state: RootState) => state.accountingSlice.inventoryChart;

export default accountingSlice.reducer;
