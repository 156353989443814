import { OkrCategoryTypeDTO } from '../../../core/dtos/okr/okr.categoryType.dto';
import { OkrCategoryEntity } from '../../../core/entities/okr.category.entity';
import { IOkrCategoryDataSource } from './data-sources/interfaces';
import { OkrCategoryDataSource } from './data-sources/okr.category.data.source';

export class OkrCategoryUseCase {
    private static instance: OkrCategoryUseCase | null = null;
    private dataSourceOkrCategory: IOkrCategoryDataSource;

    private constructor() {
        this.dataSourceOkrCategory = OkrCategoryDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new OkrCategoryUseCase();
        }
        return this.instance;
    }

    async createCategory(dto: OkrCategoryTypeDTO): Promise<OkrCategoryEntity> {
        const response = await this.dataSourceOkrCategory.postCreateCategory(dto);
        return OkrCategoryEntity.fromDTO(response);
    }

    async updateCategory(entity: OkrCategoryEntity): Promise<OkrCategoryEntity> {
        let dto = OkrCategoryEntity.toDTO(entity);
        dto = await this.dataSourceOkrCategory.putUpdateCategory(dto);
        return OkrCategoryEntity.fromDTO(dto);
    }
}
