import './OkrScreen.scss';
import { OkrSettingsComponent } from '../../components/okr/OkrSettingsComponent';
import { PeriodComponent } from '../../components/okr/PeriodComponent';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { selectIsLoadingOKR, selectPeriodListOKR } from '../../state/screens/okr/okrSlice';
import { useEffect } from 'react';
import { loadAllCategoryTypesOKR, loadAllPeriodsOKR } from '../../state/screens/okr/okrAsyncThunk';
import { OkrPeriodEntity } from '../../core/entities/okr.period.entity';
import { loadAllUsers } from '../../state/screens/user/userAsyncThunks';
import { UdpUserDTO } from '../../core/dtos/udp.user.dto';
import { selectUdpUserIsLoading, selectUdpUserList } from '../../state/screens/user/userSlice';

export function OkrScreen() {
    const dispatch = useAppDispatch();
    const isLoadingOKR: boolean = useAppSelector(selectIsLoadingOKR);
    const isLoadingUser: boolean = useAppSelector(selectUdpUserIsLoading);
    const periodList: OkrPeriodEntity[] = useAppSelector(selectPeriodListOKR);
    const userList: UdpUserDTO[] = useAppSelector(selectUdpUserList);

    useEffect(() => {
        if (periodList.length <= 0 && !isLoadingOKR) {
            dispatch(loadAllPeriodsOKR());
            dispatch(loadAllCategoryTypesOKR());
        }
    }, [dispatch, periodList, isLoadingOKR]);

    useEffect(() => {
        if (userList.length === 0 && !isLoadingUser) {
            dispatch(loadAllUsers());
        }
    }, [dispatch, userList, isLoadingUser]);

    return (
        <div className="okr-wrapper">
            <OkrSettingsComponent />
            <PeriodComponent />
        </div>
    );
}
