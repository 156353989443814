import { useAppDispatch } from '../../state/hooks';
import { ObjectiveComponent } from './ObjectiveComponent';
import { Card } from 'react-bootstrap';
import { useRef } from 'react';
import ModalObjective from './modals/ModalObjective';
import { createObjectiveOKR } from '../../state/screens/okr/okrAsyncThunk';
import { IconButtonMenu } from '../IconButtonMenu';
import { formatToPercent, getOkrProgressColor } from '../../core/utils';
import { OkrCategoryEntity } from '../../core/entities/okr.category.entity';

interface Props {
    category: OkrCategoryEntity;
}

export function CategoryComponent(props: Props) {
    const dispatch = useAppDispatch();
    const modalRefObjective = useRef<any>();

    return (
        <>
            <Card
                style={{
                    maxWidth: '600px',
                    marginTop: '30px',
                    marginRight: '30px',
                    border: '2px solid lightgrey',
                    backgroundColor: 'lightgrey',
                }}>
                <Card.Body>
                    <Card.Title
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            fontSize: '22px',
                            fontWeight: 'bold',
                            marginBottom: '20px',
                        }}>
                        <span>{props.category.categoryType.name}</span>
                        <span
                            style={{
                                color: getOkrProgressColor(props.category.progress),
                                marginLeft: 10,
                                marginRight: 10,
                            }}>
                            {formatToPercent(props.category.progress)}
                        </span>
                        <IconButtonMenu
                            // onEdit={() => {}}
                            // onDelete={() => {}}
                            onAdd={() => modalRefObjective.current.openModal()}
                            addText="Objective"
                        />
                    </Card.Title>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {props.category.objectiveList &&
                            props.category.objectiveList.map((item, index) => (
                                <ObjectiveComponent objective={item} key={`okr-objective-${item.id}`} />
                            ))}
                    </div>
                    <ModalObjective
                        ref={modalRefObjective}
                        onSubmit={data => {
                            dispatch(
                                createObjectiveOKR({
                                    name: data.name,
                                    weight: data.weight,
                                    leadUser: data.leadUser,
                                    userList: data.userList,
                                    categoryId: props.category.id,
                                }),
                            );
                            modalRefObjective.current.closeModal();
                        }}
                    />
                </Card.Body>
            </Card>
        </>
    );
}
