import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { loadAllUsers, loginUserAPI, logoutUserAPI, updateUserAPI } from './userAsyncThunks';
import { UdpUserEntity } from '../../../core/entities/udp.user.entity';

export interface UdpUserState {
    user?: UdpUserEntity;
    userList?: UdpUserEntity[];
    errorCode?: string;
    isLoading: boolean;
    displayReviewRequest: boolean;
}

const initialState: UdpUserState = {
    user: undefined,
    userList: [],
    errorCode: undefined,
    isLoading: false,
    displayReviewRequest: false,
};

export const userSlice = createSlice({
    name: 'udpUser',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(logoutUserAPI.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(logoutUserAPI.rejected, (state, payload) => {
                state.isLoading = false;
                state.errorCode = payload.error.code;
                console.error(payload);
            })
            .addCase(logoutUserAPI.fulfilled, state => {
                state.isLoading = false;
                state.user = undefined;
            })
            .addCase(loginUserAPI.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(loginUserAPI.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
            })
            .addCase(loginUserAPI.rejected, (state, action) => {
                state.errorCode = action.error.code;
                console.error(action);
                state.isLoading = false;
                state.user = undefined;
            })
            .addCase(loadAllUsers.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(loadAllUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userList = action.payload;
            })
            .addCase(loadAllUsers.rejected, (state, action) => {
                state.errorCode = action.error.code;
                console.error(action);
                state.isLoading = false;
            })
            .addCase(updateUserAPI.pending, state => {
                state.isLoading = true;
                state.errorCode = undefined;
            })
            .addCase(updateUserAPI.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
            })
            .addCase(updateUserAPI.rejected, (state, action) => {
                state.errorCode = action.error.code;
                console.error(action);
                state.isLoading = false;
            });
    },
});

export const selectUdpUser = (state: RootState) => state.udpUser.user;
export const selectUdpUserList = (state: RootState) => state.udpUser.userList;
export const selectUdpUserIsLoading = (state: RootState) => state.udpUser.isLoading;
export const selectUdpUserErrorCode = (state: RootState) => state.udpUser.errorCode;
export const selectUdpUserDisplayReviewRequest = (state: RootState) => state.udpUser.displayReviewRequest;

export default userSlice.reducer;
