import { useCallback, useEffect, useState } from 'react';
import './KpiComponent.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form } from 'react-bootstrap';
import { calcStartDate, DurationType, calcEndDate } from '../../../core/dtos/duration.type';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
    getAnalysisClv,
    getAnalysisInventory,
    getAnalysisRevenue,
} from '../../../state/screens/accounting/accountingAsyncThunk';
import { Line } from 'react-chartjs-2';
import {
    selectAccountingClvChart,
    selectAccountingInventoryChart,
    selectAccountingIsLoading,
    selectAccountingRevenueChart,
} from '../../../state/screens/accounting/accountingSlice';
import { de } from 'date-fns/locale';
import { ChartOptions } from 'chart.js';

export function KpiComponent() {
    const dispatch = useAppDispatch();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [expectedYears, setExpectedYears] = useState(3);
    const [durationType, setDurationType] = useState<DurationType>(DurationType.MONTH);

    const isLoading = useAppSelector(selectAccountingIsLoading);
    const revenueChart = useAppSelector(selectAccountingRevenueChart);
    const clvChart = useAppSelector(selectAccountingClvChart);
    const inventoryChart = useAppSelector(selectAccountingInventoryChart);

    const updateChartData = useCallback(
        (pStartDate: Date, pEndDate: Date) => {
            if (!isLoading) {
                dispatch(
                    getAnalysisRevenue({
                        durationType,
                        startDate: pStartDate,
                        endDate: pEndDate,
                    }),
                ).then(() => {
                    dispatch(
                        getAnalysisClv({
                            durationType,
                            startDate: pStartDate,
                            endDate: pEndDate,
                            expectedYears,
                        }),
                    ).then(() => {
                        dispatch(
                            getAnalysisInventory({
                                durationType,
                                startDate: pStartDate,
                                endDate: pEndDate,
                            }),
                        );
                    });
                });
            }
        },
        [dispatch, durationType, expectedYears, isLoading],
    );

    useEffect(() => {
        const endDateValue = calcEndDate();
        setEndDate(endDateValue);
        const startDateValue = calcStartDate(durationType, endDateValue);
        setStartDate(startDateValue);
    }, [durationType]);

    const optionsRevenue: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Umsatz',
            },
        },
    };

    const optionsAov: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Average Order Value (AOV)',
            },
        },
    };

    const optionsClv: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Customer Lifetime Value (CLV)',
            },
        },
    };

    const optionsFlv: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Farm Lifetime Value (FLV)',
            },
        },
    };

    const optionsInventory: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Anzahl Verkäufe',
            },
        },
        scales: {
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                title: {
                    display: true,
                    text: 'Anzahl Farms',
                },
            },
            y2: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: 'Anzahl Pflanzen',
                },
            },
        },
    };

    return (
        <>
            <div className="filter-container">
                <div className="input-container">
                    <span className="input-label">Inverval wählen</span>
                    <Form.Select
                        aria-label="Interval wählen"
                        value={durationType}
                        onChange={e => {
                            let value = parseInt(e.target.value);
                            setDurationType(value);
                        }}>
                        <option value={DurationType.DAY}>Tag</option>
                        <option value={DurationType.WEEK}>Woche</option>
                        <option value={DurationType.MONTH}>Monat</option>
                        <option value={DurationType.QUARTER}>Quartal</option>
                        <option value={DurationType.YEAR}>Jahr</option>
                    </Form.Select>
                </div>
                <div className="input-container">
                    <span className="input-label">Startdatum</span>
                    <DatePicker
                        selected={startDate}
                        dateFormat="dd.MM.yyyy  HH:mm 'Uhr'"
                        onChange={date => setStartDate(date)}
                        showTimeInput
                        timeInputLabel="Uhrzeit:"
                        isClearable
                        timeIntervals={60}
                        locale={de}
                    />
                </div>
                <div className="input-container">
                    <span className="input-label">Enddatum</span>
                    <DatePicker
                        selected={endDate}
                        dateFormat="dd.MM.yyyy  HH:mm 'Uhr'"
                        onChange={date => setEndDate(date)}
                        showTimeInput
                        timeInputLabel="Uhrzeit:"
                        isClearable
                        timeIntervals={60}
                        locale={de}
                    />
                </div>
                <div className="input-container">
                    <span className="input-label">Kundendauer (Jahre)</span>
                    <Form.Control
                        className="mobileBox"
                        required
                        name="mobile"
                        type="number"
                        value={expectedYears}
                        onChange={e => {
                            let value = parseFloat(e.target.value);
                            setExpectedYears(value);
                        }}
                    />
                </div>
                <div className="input-container">
                    <Button variant="primary" onClick={() => updateChartData(startDate, endDate)} disabled={isLoading}>
                        Analyse starten
                    </Button>
                </div>
            </div>
            <div className="outer-chart-container">
                {revenueChart.revenueData && (
                    <div className="inner-chart-container">
                        <Line options={optionsRevenue} data={revenueChart.revenueData} />
                    </div>
                )}
                {revenueChart.aovData && (
                    <div className="inner-chart-container">
                        <Line options={optionsAov} data={revenueChart.aovData} />
                    </div>
                )}
                {clvChart.clvData && (
                    <div className="inner-chart-container">
                        <Line options={optionsClv} data={clvChart.clvData} />
                    </div>
                )}
                {clvChart.flvData && (
                    <div className="inner-chart-container">
                        <Line options={optionsFlv} data={clvChart.flvData} />
                    </div>
                )}
                {inventoryChart.inventoryData && (
                    <div className="inner-chart-container">
                        <Line options={optionsInventory} data={inventoryChart.inventoryData} />
                    </div>
                )}
            </div>
        </>
    );
}
