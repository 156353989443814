import { createAsyncThunk } from '@reduxjs/toolkit';
import { OkrPeriodUseCase } from '../../../features/okr/period/okr.period.use.case';
import { OkrCreatePeriodDTO } from '../../../core/dtos/okr/okr.period.dto';
import { OkrCategoryTypeUseCase } from '../../../features/okr/categoryType/okr.categoryType.use.case';
import { OkrCategoryUseCase } from '../../../features/okr/category/okr.category.use.case';
import { OkrObjectiveUseCase } from '../../../features/okr/objective/okr.objective.use.case';
import { OkrCategoryTypeDTO, OkrCreateCategoryTypeDTO } from '../../../core/dtos/okr/okr.categoryType.dto';
import { OkrKeyResultUseCase } from '../../../features/okr/keyResult/okr.keyResult.use.case';
import { OkrPeriodEntity } from '../../../core/entities/okr.period.entity';
import { OkrKeyResultEntity } from '../../../core/entities/okr.keyResult.entity';
import { OkrObjectiveEntity } from '../../../core/entities/okr.objective.entity';
import { OkrCategoryEntity } from '../../../core/entities/okr.category.entity';

// Period
export const loadPeriodOKR = createAsyncThunk('okrScreen/loadPeriod', async (periodId: string) => {
    const useCase = OkrPeriodUseCase.getInstance();
    return await useCase.getPeriod(periodId);
});

export const loadAllPeriodsOKR = createAsyncThunk('okrScreen/loadAllPeriods', async () => {
    const useCase = OkrPeriodUseCase.getInstance();
    return await useCase.getAllPeriods();
});

export const createPeriodOKR = createAsyncThunk('okrScreen/createPeriod', async (dto: OkrCreatePeriodDTO) => {
    const useCase = OkrPeriodUseCase.getInstance();
    return await useCase.createPeriod(dto);
});

export const updatePeriodOKR = createAsyncThunk('okrScreen/updatePeriod', async (entity: OkrPeriodEntity) => {
    const useCase = OkrPeriodUseCase.getInstance();
    return await useCase.updatePeriod(entity);
});

// Category Type
export const loadAllCategoryTypesOKR = createAsyncThunk('okrScreen/loadAllCategoryTypes', async () => {
    const useCase = OkrCategoryTypeUseCase.getInstance();
    return await useCase.getAllCategoryTypes();
});

export const createCategoryTypeOKR = createAsyncThunk(
    'okrScreen/createCategoryType',
    async (dto: OkrCreateCategoryTypeDTO) => {
        const useCase = OkrCategoryTypeUseCase.getInstance();
        return await useCase.createCategoryType(dto);
    },
);

export const updateCategoryTypeOKR = createAsyncThunk(
    'okrScreen/updateCategoryType',
    async (dto: OkrCategoryTypeDTO) => {
        const useCase = OkrCategoryTypeUseCase.getInstance();
        return await useCase.updateCategoryType(dto);
    },
);

// Category
export const createCategoryOKR = createAsyncThunk('okrScreen/createCategory', async (dto: OkrCategoryTypeDTO) => {
    const useCase = OkrCategoryUseCase.getInstance();
    return await useCase.createCategory(dto);
});

export const updateCategoryOKR = createAsyncThunk('okrScreen/updateCategory', async (dto: OkrCategoryEntity) => {
    const useCase = OkrCategoryUseCase.getInstance();
    return await useCase.updateCategory(dto);
});

// Objective
export const createObjectiveOKR = createAsyncThunk('okrScreen/createObjective', async (dto: OkrObjectiveEntity) => {
    const useCase = OkrObjectiveUseCase.getInstance();
    return await useCase.createObjective(dto);
});

export const updateObjectiveOKR = createAsyncThunk('okrScreen/updateObjective', async (dto: OkrObjectiveEntity) => {
    const useCase = OkrObjectiveUseCase.getInstance();
    return await useCase.updateObjective(dto);
});

export const deleteObjectiveOKR = createAsyncThunk('okrScreen/deleteObjective', async (id: string) => {
    const useCase = OkrObjectiveUseCase.getInstance();
    return await useCase.deleteObjective(id);
});

// KeyResult
export const createKeyResultOKR = createAsyncThunk('okrScreen/createKeyResult', async (dto: OkrKeyResultEntity) => {
    const useCase = OkrKeyResultUseCase.getInstance();
    return await useCase.createKeyResult(dto);
});

export const updateKeyResultOKR = createAsyncThunk('okrScreen/updateKeyResult', async (dto: OkrKeyResultEntity) => {
    const useCase = OkrKeyResultUseCase.getInstance();
    return await useCase.updateKeyResult(dto);
});

export const deleteKeyResultOKR = createAsyncThunk('okrScreen/deleteKeyResult', async (id: string) => {
    const useCase = OkrKeyResultUseCase.getInstance();
    return await useCase.deleteKeyResult(id);
});
