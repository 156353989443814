import { createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerDTO } from '../../../core/dtos/customer.dto';
import { CustomerFilterDTO } from '../../../core/dtos/customer.filter.dto';
import { CustomerDetailUseCase } from '../../../features/customer/load/customer.details.use.case';
import { CustomerSearchUseCase } from '../../../features/customer/search/customer.search.use.case';

export const customerSearchByFilter = createAsyncThunk(
    'customerSearchScreen/searchByFilter',
    async (filterDTO: CustomerFilterDTO) => {
        const useCase = CustomerSearchUseCase.getInstance();
        return await useCase.byFilter(filterDTO);
    },
);

export const customerLoadDetails = createAsyncThunk(
    'customerOverviewScreen/loadDetails',
    async (customerId: string) => {
        const useCase = CustomerDetailUseCase.getInstance();
        const customer: CustomerDTO = await useCase.getCustomerDetails(customerId);
        return customer;
    },
);
