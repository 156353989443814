import { backendAxios } from '../../../../core/axios';
import { CustomerFilterDTO } from '../../../../core/dtos/customer.filter.dto';
import { CustomerFilterResponseDTO } from '../../../../core/dtos/customer.filter.response.dto';
import { ICustomerSearchByFilterDataSource } from './interfaces';

export class CustomerSearchByFilterDataSource implements ICustomerSearchByFilterDataSource {
    private static instance: CustomerSearchByFilterDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new CustomerSearchByFilterDataSource();
        }
        return this.instance;
    }

    async searchByFilter(filterDTO: CustomerFilterDTO): Promise<CustomerFilterResponseDTO> {
        try {
            const response = await backendAxios.post<CustomerFilterResponseDTO>(`/customers`, filterDTO);
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
