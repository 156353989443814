export enum AnalysisType {
    ALL,
    ONLY_FARMS,
    ONLY_OTHER,
    FARMS_WIHTOUT_URBANFARM_AND_REFURBISHED,
    FARMS_ONLY_URBANFARM_AND_REFURBISHED,
}

const strArray = [
    'Alle',
    'Nur Farms',
    'Nur andere',
    'Farms (ohne urbanfarm & refurbished)',
    'Nur urbanfarm & refurbished',
];

export function getAnalysisTypeStringArray() {
    return strArray;
}

export function getAnalysisTypeString(value: number) {
    if (value >= 0 && value < strArray.length) {
        return strArray[value];
    }
    return 'Keine Zuordnung: ' + value;
}
