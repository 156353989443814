import { backendAxios } from '../../../../core/axios';
import { OkrCategoryTypeDTO, OkrCreateCategoryTypeDTO } from '../../../../core/dtos/okr/okr.categoryType.dto';
import { IOkrCategoryTypeDataSource } from './interfaces';

export class OkrCategoryTypeDataSource implements IOkrCategoryTypeDataSource {
    private static instance: OkrCategoryTypeDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new OkrCategoryTypeDataSource();
        }
        return this.instance;
    }

    async getAllCategoryTypes(): Promise<OkrCategoryTypeDTO[]> {
        try {
            const response = await backendAxios.get<OkrCategoryTypeDTO[]>(`/okr/categoryType`, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async postCreateCategoryType(dto: OkrCreateCategoryTypeDTO): Promise<OkrCategoryTypeDTO> {
        try {
            const response = await backendAxios.post<OkrCategoryTypeDTO>(`/okr/categoryType`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async putUpdateCategoryType(dto: OkrCategoryTypeDTO): Promise<OkrCategoryTypeDTO> {
        try {
            const response = await backendAxios.put<OkrCategoryTypeDTO>(`/okr/categoryType`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
