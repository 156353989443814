import { KpiComponent } from '../../components/dashboard/KpiComponent/KpiComponent';
import './DashboardScreen.scss';

interface DashboardScreenProps {}

export function DashboardScreen(props: DashboardScreenProps) {
    return (
        <div className="dashboard-wrapper">
            <KpiComponent />
        </div>
    );
}
