import { UdpUserDTO } from '../../core/dtos/udp.user.dto';
import { OkrKeyResultEntity } from '../../core/entities/okr.keyResult.entity';
import { formatToPercent, getOkrProgressColor } from '../../core/utils';
import { IconButtonMenu } from '../IconButtonMenu';

interface Props {
    keyResult?: OkrKeyResultEntity;
    leadUser: UdpUserDTO;
    onEdit: () => any;
    onDelete: () => any;
}

export function KeyResultComponent(props: Props) {
    return (
        <>
            {props.keyResult && (
                <tr>
                    <td>
                        {props.keyResult.isInitiative ? 'Initiative' : 'KeyResult'}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {props.keyResult.userList
                                .filter(el => el?.okrColor !== props.leadUser?.okrColor)
                                .map((item, index) => (
                                    <div
                                        key={`objective-color-${item.uid}`}
                                        style={{
                                            width: 15,
                                            height: 15,
                                            borderRadius: 7,
                                            margin: 3,
                                            backgroundColor: item?.okrColor,
                                            border: '1px solid black',
                                        }}></div>
                                ))}
                        </div>
                    </td>
                    <td>{props.keyResult.name}</td>
                    <td>
                        <span style={{ whiteSpace: 'nowrap', color: getOkrProgressColor(props.keyResult.progress) }}>
                            {formatToPercent(props.keyResult.progress)}
                        </span>
                    </td>
                    <td>
                        <IconButtonMenu onEdit={props.onEdit} onDelete={props.onDelete} />
                    </td>
                </tr>
            )}
        </>
    );
}
