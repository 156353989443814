import { User } from 'firebase/auth';
import { FbUserLoginDataSource } from './data-sources/fb.user.login.data.source';
import { IUserLoginAuthDataSource } from './data-sources/interfaces';

export class UserLoginUseCase {
    private static instance: UserLoginUseCase | null = null;

    private dataSourceAuth: IUserLoginAuthDataSource;

    private constructor() {
        this.dataSourceAuth = FbUserLoginDataSource.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {
            this.instance = new UserLoginUseCase();
        }
        return this.instance;
    }

    async login(username: string, password: string): Promise<User> {
        const user = await this.dataSourceAuth.login(username, password);
        return user;
    }

    async resetPassword(username: string): Promise<void> {
        await this.dataSourceAuth.resetPassword(username);
    }
}
