import { useState } from 'react';
import './OkrSettingsComponent.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { Accordion, Button, Card, Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import DatePicker from 'react-datepicker';
import { de } from 'date-fns/locale';
import { selectCategoryTypeListOKR, selectIsLoadingOKR } from '../../state/screens/okr/okrSlice';
import { createCategoryTypeOKR, createPeriodOKR } from '../../state/screens/okr/okrAsyncThunk';
import { OkrCategoryTypeDTO } from '../../core/dtos/okr/okr.categoryType.dto';
import { format } from 'date-fns';
import { UdpUserEntity } from '../../core/entities/udp.user.entity';
import { selectUdpUserList } from '../../state/screens/user/userSlice';

export function OkrSettingsComponent() {
    const dispatch = useAppDispatch();
    const isLoading: boolean = useAppSelector(selectIsLoadingOKR);
    const [newPeriodName, setNewPeriodName] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [newCategoryName, setNewCategoryName] = useState('');
    const categoryTypeList: OkrCategoryTypeDTO[] = useAppSelector(selectCategoryTypeListOKR);
    const [selectedCategoryTypeList, setSelectedCategoryTypeList] = useState<OkrCategoryTypeDTO[]>([]);
    const userList: UdpUserEntity[] = useAppSelector(selectUdpUserList);

    function checkCategoryType(categoryType: OkrCategoryTypeDTO, checked: boolean) {
        const list = selectedCategoryTypeList;
        const idx = list.findIndex(el => el.id === categoryType.id);
        if (idx < 0 && checked) {
            list.push(categoryType);
        } else if (idx >= 0) {
            list.splice(idx, 1);
        }
        setSelectedCategoryTypeList(list);
    }

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Einstellungen</Accordion.Header>
                    <Accordion.Body>
                        <div className="settings-body-wrapper">
                            <Card style={{ width: '350px', marginRight: '20px' }}>
                                <Card.Body>
                                    <Card.Title>Neuen OKR-Zyklus erstellen</Card.Title>
                                    <Form style={{ marginTop: '20px' }}>
                                        <Form.Label htmlFor="periodName">Name für den neuen Zyklus</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="periodName"
                                            value={newPeriodName}
                                            onChange={e => {
                                                setNewPeriodName(e.target.value);
                                            }}
                                        />
                                    </Form>
                                    <div className="okr-date-input-container">
                                        <span className="input-label">Startdatum</span>
                                        <DatePicker
                                            selected={startDate}
                                            dateFormat="dd.MM.yyyy"
                                            onChange={date => setStartDate(date)}
                                            isClearable
                                            locale={de}
                                        />
                                    </div>
                                    <div className="okr-date-input-container">
                                        <span className="input-label">Enddatum</span>
                                        <DatePicker
                                            selected={endDate}
                                            dateFormat="dd.MM.yyyy"
                                            onChange={date => setEndDate(date)}
                                            isClearable
                                            locale={de}
                                        />
                                    </div>
                                    <Accordion style={{ marginTop: '20px' }}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Kategorien</Accordion.Header>
                                            <Accordion.Body>
                                                <div key={`inline-checkbox`} className="mb-3">
                                                    {categoryTypeList.map((categoryType, idx) => (
                                                        <Form.Check
                                                            key={`categoryType-${categoryType.id}`}
                                                            inline
                                                            label={categoryType.name}
                                                            name={`categoryType-${categoryType.id}`}
                                                            type={'checkbox'}
                                                            onChange={e =>
                                                                checkCategoryType(categoryType, e.target.checked)
                                                            }
                                                            id={`inline-checkbox-${idx}`}
                                                        />
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                                <Card.Footer>
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            dispatch(
                                                createPeriodOKR({
                                                    name: newPeriodName,
                                                    startDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
                                                    endDate: format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
                                                    categoryList: selectedCategoryTypeList,
                                                }),
                                            )
                                        }
                                        disabled={isLoading}>
                                        Hinzufügen
                                    </Button>
                                </Card.Footer>
                            </Card>
                            <Card style={{ width: '350px', marginRight: '20px' }}>
                                <Card.Body>
                                    <Card.Title>Neuen CategoryType erstellen</Card.Title>
                                    <Form style={{ marginTop: '20px' }}>
                                        <Form.Label htmlFor="categoryName">Name der neuen Kategorie</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="categoryName"
                                            value={newCategoryName}
                                            onChange={e => {
                                                setNewCategoryName(e.target.value);
                                            }}
                                        />
                                    </Form>
                                </Card.Body>
                                <Card.Footer>
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            dispatch(
                                                createCategoryTypeOKR({ name: newCategoryName, periodId: undefined }),
                                            )
                                        }
                                        disabled={isLoading}>
                                        Hinzufügen
                                    </Button>
                                </Card.Footer>
                            </Card>
                            <Card style={{ width: '350px', marginRight: '20px' }}>
                                <Card.Body>
                                    <Card.Title>Mitglieder OKR</Card.Title>
                                    {userList.map((user, index) => (
                                        <div
                                            key={`settings-user-${user.uid}`}
                                            style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                                            <div
                                                style={{
                                                    marginRight: '20px',
                                                    height: '20px',
                                                    width: '50px',
                                                    borderRadius: '10px',
                                                    backgroundColor: user?.okrColor ?? 'transparent',
                                                    borderColor: user?.okrColor ?? 'black',
                                                }}
                                            />
                                            <span style={{ lineHeight: '20px' }}>
                                                {user.firstName && user.lastName
                                                    ? `${user.firstName} ${user.lastName}`
                                                    : user.email}
                                            </span>
                                        </div>
                                    ))}
                                </Card.Body>
                                <Card.Footer></Card.Footer>
                            </Card>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}
