import { CategoryComponent } from './CategoryComponent';
import { OkrPeriodEntity } from '../../core/entities/okr.period.entity';
import { IconButtonMenu } from '../IconButtonMenu';
import { formatToPercent, getOkrProgressColor } from '../../core/utils';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { selectIsLoadingOKR, selectPeriodListOKR, selectPeriodOKR } from '../../state/screens/okr/okrSlice';
import { Form, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { loadPeriodOKR } from '../../state/screens/okr/okrAsyncThunk';

export function PeriodComponent() {
    const dispatch = useAppDispatch();
    const isLoadingOKR: boolean = useAppSelector(selectIsLoadingOKR);
    const periodList: OkrPeriodEntity[] = useAppSelector(selectPeriodListOKR);
    const period: OkrPeriodEntity = useAppSelector(selectPeriodOKR);
    const [selectedPeriod, setSelectedPeriod] = useState<string>('');

    useEffect(() => {
        if (periodList && periodList.length > 0 && !period && !isLoadingOKR) {
            dispatch(loadPeriodOKR(periodList[0].id));
        }
    }, [dispatch, periodList, period, isLoadingOKR]);

    useEffect(() => {
        if (selectedPeriod?.length > 0) {
            dispatch(loadPeriodOKR(selectedPeriod));
        }
    }, [dispatch, selectedPeriod]);

    return (
        <>
            <h1
                style={{
                    fontWeight: 'bold',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontSize: '32px',
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                    }}>
                    <span
                        style={{
                            marginRight: 30,
                            lineHeight: '50px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                        }}>
                        OKR Zyklus
                    </span>
                    <Form.Select
                        value={selectedPeriod}
                        onChange={e => {
                            setSelectedPeriod(e.target.value);
                        }}>
                        {periodList.map((p, idx) => (
                            <option value={p.id} key={`period-option-${p.id}`}>
                                {p.name}
                            </option>
                        ))}
                    </Form.Select>
                    {period && (
                        <span
                            style={{
                                marginLeft: 30,
                                marginRight: 30,
                                lineHeight: '50px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                color: getOkrProgressColor(period.progress),
                            }}>
                            {formatToPercent(period.progress)}
                        </span>
                    )}
                    <span
                        style={{
                            marginRight: 30,
                            lineHeight: '50px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                        }}>
                        <IconButtonMenu
                            // onEdit={() => {}}
                            // onDelete={() => {}}
                            // onAdd={() => {}}
                            addText="Kategorie"
                        />
                    </span>
                </div>
            </h1>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {period?.categoryList &&
                    period.categoryList.map((item, index) => (
                        <CategoryComponent category={item} key={`okr-category-${item.id}`} />
                    ))}
                {isLoadingOKR && (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )}
            </div>
        </>
    );
}
