import { backendAxios } from '../../../../core/axios';
import { OkrKeyResultDTO } from '../../../../core/dtos/okr/okr.keyResult.dto';
import { IKeyResultDataSource } from './interfaces';

export class KeyResultDataSource implements IKeyResultDataSource {
    private static instance: KeyResultDataSource | null = null;

    private constructor() {}

    static getInstance() {
        if (this.instance === null) {
            this.instance = new KeyResultDataSource();
        }
        return this.instance;
    }

    async postCreateKeyResult(dto: OkrKeyResultDTO): Promise<OkrKeyResultDTO> {
        try {
            const response = await backendAxios.post<OkrKeyResultDTO>(`/okr/keyResult`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async putUpdateKeyResult(dto: OkrKeyResultDTO): Promise<OkrKeyResultDTO> {
        try {
            const response = await backendAxios.put<OkrKeyResultDTO>(`/okr/keyResult`, dto, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async deleteKeyResult(id: string): Promise<void> {
        try {
            const response = await backendAxios.delete<void>(`/okr/keyResult/${id}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
