import { RevenueDTO } from '../dtos/revenue.dto';

export class RevenueEntity {
    readonly revenue: number;
    readonly aov: number;

    constructor(entity: RevenueEntity) {
        Object.assign(this, entity);
    }

    static fromDTO(dto: RevenueDTO) {
        return new RevenueEntity({
            revenue: dto.revenue,
            aov: dto.aov,
        });
    }

    static toDTO(entity: RevenueEntity): RevenueDTO {
        return {
            revenue: entity.revenue,
            aov: entity.aov,
        };
    }
}
