import { JSONTree } from 'react-json-tree';
import { ShopifyCustomer } from '../../../core/dtos/customer.dto';
import './ShopifyCustomerComponent.scss';

interface ShopifyCustomerComponentProps {
    customer: ShopifyCustomer;
}

export function ShopifyCustomerComponent(props: ShopifyCustomerComponentProps) {
    return <>{props.customer && <JSONTree data={props.customer} />}</>;
}
